import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Paper, Box, Typography, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  useGetDeviceDetailQuery,
  useUpdateDeviceMutation,
  useListRegionsQuery,
  useUnregisterDeviceMutation,
} from "../../util/client/TcApi";
import FullPageLoader from "../../components/FullPageLoader";
import QRCode from "react-qr-code";
import { createDeviceForm } from "../../forms/createDeviceForm";
import TcForm from "../../components/tc-form/TcForm";
import { DeviceApi } from "../../lib";
const DeviceDetailPage = () => {
  const { partnerId, deviceId } = useParams() as {
    partnerId: string;
    deviceId: string;
  };
  const {
    isFetching: fetchingDevice,
    data: deviceData,
    refetch,
  } = useGetDeviceDetailQuery({
    partnerId,
    deviceId,
  } as DeviceApi.GetDeviceRequest);
  const [unregisterDevice] = useUnregisterDeviceMutation();
  const { isFetching: fetchingRegions, data: regionData } = useListRegionsQuery(
    { partnerId }
  );
  const [triggerUpdateDevice, { isLoading: updatingDevice }] =
    useUpdateDeviceMutation();
  const [formData, setFormData] = useState({
    name: "",
    regionId: "",
    hexnodeDeviceId: 0,
    adminPassword: "",
  });
  const onSaveClick = useCallback(() => {
    triggerUpdateDevice({
      partnerId,
      deviceId,
      payload: formData,
    });
  }, [formData, triggerUpdateDevice]);
  useEffect(() => {
    if (deviceData) {
      setFormData({
        name: deviceData.device.name,
        regionId: deviceData.deviceConfig.regionId,
        hexnodeDeviceId: deviceData.device.hexnodeDeviceId,
        adminPassword: deviceData.device.adminPassword || "abc123",
      });
    }
  }, [deviceData]);
  const deviceForm = useMemo(() => {
    const options = (regionData?.regions || []).map((region) => ({
      label: region.name,
      value: region.id,
    }));
    return createDeviceForm(options);
  }, [regionData]);
  const isLoading = fetchingDevice || fetchingRegions || updatingDevice;
  const isRegistered = deviceData?.device.registrationOtp === undefined;
  return (
    <Paper sx={{ minHeight: "100vh", width: "100%", pt: 8, overflowY: "auto" }}>
      <FullPageLoader isActive={isLoading} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pt: 2,
          px: 1,
        }}
      >
        {isRegistered ? (
          <Box>
            <Button
              variant="outlined"
              onClick={() =>
                unregisterDevice({ deviceId, partnerId }).then(() => refetch())
              }
            >
              Unregister Device
            </Button>
          </Box>
        ) : (
          <Box>
            <Typography variant="h4">Onboarding QR</Typography>
            <QRCode
              value={JSON.stringify({
                partnerId,
                deviceId: deviceData?.device?.id,
                registrationOtp: deviceData?.device?.registrationOtp,
              })}
              size={250}
            />
            <Box>
              <Typography>Partner Id: {partnerId}</Typography>
              <Typography>Device Id: {deviceData?.device?.id}</Typography>
              <Typography>
                Registration OTP: {deviceData?.device?.registrationOtp}
              </Typography>
            </Box>
          </Box>
        )}
        <Box sx={{ height: 16 }} />
        <Box sx={{ maxWidth: { xs: "100%", sm: 500 } }}>
          <TcForm
            schema={deviceForm}
            onFormChange={setFormData}
            value={formData}
          />
          <Button onClick={onSaveClick}>Save</Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default DeviceDetailPage;
