import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Typography,
} from "@mui/material";
import QRCode from "react-qr-code";
import { Drt } from "../../lib/Drt";

type PoiDialogProps = {
  selectedGeometry?: Drt.TrailGeometry;
  onRequestClose: () => void;
};

const PoiDialog = ({ onRequestClose, selectedGeometry }: PoiDialogProps) => {
  const open = Boolean(selectedGeometry && selectedGeometry.type === "Point");
  if (selectedGeometry?.type === "Point") {
    const text = selectedGeometry.properties.text || "";
    const lines = text.split("\n");
    const qr = `geo:${selectedGeometry.coordinates[1]},${selectedGeometry.coordinates[0]}`;
    const url = `https://www.google.com/maps/search/?api=1&query=${selectedGeometry.coordinates[1]},${selectedGeometry.coordinates[0]}`;
    console.log(url);
    return (
      <Dialog
        open={open}
        onClose={onRequestClose}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
          },
        }}
      >
        <DialogContent>
          {lines.map((line, i) => (
            <Typography variant="body1" key={i}>
              {line}
            </Typography>
          ))}
          {selectedGeometry.properties.renderLocationQr && (
            <>
              <Box sx={{ py: 1, display: "flex", justifyContent: "center" }}>
                {/* @ts-ignore */}
                <QRCode value={qr} size={128} />
              </Box>
              <Typography variant="body1">
                Scan the QR above to open up maps on your phone to navigate to
                this point or click{" "}
                <Link href={url} target="_blank">
                  here
                </Link>{" "}
                to open maps on this device
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onRequestClose}>Dismiss</Button>
        </DialogActions>
      </Dialog>
    );
  }
  return <></>;
};

export default PoiDialog;
