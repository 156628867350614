import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useCallback, useEffect, useState } from "react";
import TcForm from "../../components/tc-form/TcForm";
import DashboardCard from "./DashboardCard";
import { createPartnerConfigForm } from "../../forms/createPartnerConfigForm";
import { useCreatePartnerConfigMutation } from "../../util/client/TcApi";
import { useNavigate } from "react-router-dom";
// import { useCreatePartnerConfigMutation } from "../../util/client/TcApi";

type CreatePartnerConfigProps = {
  partnerId: string;
};

const CreatePartnerConfig = ({ partnerId }: CreatePartnerConfigProps) => {
  const navigate = useNavigate();
  const [triggerCreatePartnerConfig, { data, isSuccess }] =
    useCreatePartnerConfigMutation();
  const [formData, setFormData] = useState({ name: "" });
  const [isOpen, setIsOpen] = useState(false);
  const onSubmit = useCallback(() => {
    console.log("creating PartnerConfig", formData);
    triggerCreatePartnerConfig({ partnerId, payload: formData });
  }, [formData, partnerId, triggerCreatePartnerConfig]);
  const onRequestClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const onOpenDialog = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  useEffect(() => {
    if (isSuccess && isOpen && data) {
      setIsOpen(false);
      navigate(`/partner/${partnerId}/partner-config/${data.id}`);
    }
  }, [isSuccess, isOpen, setIsOpen, data]);
  return (
    <>
      <DashboardCard>
        <Box
          onClick={onOpenDialog}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            width: "100%",
            height: "100%",
          }}
        >
          <AddIcon fontSize="large" />
          <Typography>Add Partner Config</Typography>
        </Box>
      </DashboardCard>
      <Dialog open={isOpen} onClose={onRequestClose}>
        <DialogTitle>Create PartnerConfig</DialogTitle>
        <DialogContent>
          <Box sx={{ minWidth: 500 }}>
            <TcForm
              schema={createPartnerConfigForm}
              onFormChange={setFormData}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onRequestClose}>Cancel</Button>
          <Button onClick={onSubmit}>Create</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreatePartnerConfig;
