import { Box, Typography } from "@mui/material";
import React from "react";
import { ApiCommon } from "../../lib";
import DashboardCard from "./DashboardCard";

type PartnerCardProps = {
  partner: ApiCommon.BasePartner;
  onClick: () => void;
};
const PartnerCard = ({ partner, onClick }: PartnerCardProps) => {
  return (
    <DashboardCard>
      <Box
        onClick={onClick}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          width: "100%",
          height: "100%",
        }}
      >
        <Typography variant="h6">{partner.name}</Typography>
      </Box>
    </DashboardCard>
  );
};

export default PartnerCard;
