import React, { useCallback } from "react";
import DropzoneCard from "../../components/DropzoneCard";
import { Drt } from "../../lib/Drt";
import { getTrailFromUpload } from "../../util/file-utils";

const processFile = (file: File, onAddTrail: (trail: Drt.Trail) => void) => {
  console.log("processing file", file);
  getTrailFromUpload(file).then((trail) => {
    console.log("new trail!", trail);
    onAddTrail(trail);
  });
};

type TrailDropzoneProps = {
  onAddTrail: (trail: Drt.Trail) => void;
};

const TrailDropzone = ({ onAddTrail }: TrailDropzoneProps) => {
  const onDrop = useCallback(
    (files: File[]) => {
      console.log("files", files);
      files.forEach((file) => processFile(file, onAddTrail));
    },
    [onAddTrail]
  );
  return (
    <DropzoneCard
      text="Drag an drop geojson, kml, or gpx files here to add another trail"
      onDrop={onDrop}
    />
  );
};

export default TrailDropzone;
