import { Box, Typography, IconButton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { ApiCommon } from "../../lib";
import DashboardCard from "./DashboardCard";
import EditIcon from "@mui/icons-material/Edit";

type RegionConfigCardProps = {
  partnerConfig: ApiCommon.PartnerConfigItem;
  partnerId: string;
};
const RegionConfigCard = ({
  partnerConfig,
  partnerId,
}: RegionConfigCardProps) => {
  return (
    <DashboardCard>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Typography>{partnerConfig.name}</Typography>
        <Box sx={{ display: "flex" }}>
          <Link to={`/partner/${partnerId}/partner-config/${partnerConfig.id}`}>
            <IconButton aria-label="edit" color="primary">
              <EditIcon />
            </IconButton>
          </Link>
        </Box>
      </Box>
    </DashboardCard>
  );
};

export default RegionConfigCard;
