import React from "react";
import { Permissions, hasPermissionsFor } from "../../lib";
import usePermissions from "./usePermissions";

type Props = React.PropsWithChildren & {
  perms: Permissions.Perm;
};

const PermGuard = ({ children, perms }: Props) => {
  const { data } = usePermissions();
  if (data) {
    const { role, plan } = data;
    if (hasPermissionsFor(role, plan, perms)) {
      return <>{children}</>;
    }
  }
  return null;
};

export default PermGuard;
