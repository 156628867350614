import { FormSchema } from "../components/tc-form/types";

export const createPartnerForm: FormSchema = [
  [{ type: "text", propName: "name", label: "Partner Name" }],
  [
    { type: "text", propName: "devicePrefix", label: "Device Prefix" },
    {
      type: "email",
      propName: "ownerEmail",
      label: "Owner Email",
      rowWeight: 2,
    },
  ],
  [{ type: "multiline", propName: "address", label: "Address", rows: 4 }],
];
