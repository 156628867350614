import { Box, Typography } from "@mui/material";
import React from "react";
import { ApiCommon } from "../../lib";
import DashboardCard from "./DashboardCard";

type UserCardProps = {
  user: ApiCommon.User;
};
const UserCard = ({ user }: UserCardProps) => {
  return (
    <DashboardCard>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          width: "100%",
          height: "100%",
        }}
      >
        <Typography variant="h6">{user.email}</Typography>
      </Box>
    </DashboardCard>
  );
};

export default UserCard;
