import { Box, Toolbar } from "@mui/material";
import React from "react";

type Props = React.PropsWithChildren & {
  hasAppBar: boolean;
};

const PageTemplate = ({ hasAppBar, children }: Props) => {
  return (
    <Box sx={{ width: "100%", p: 1 }}>
      {hasAppBar && <Toolbar />}
      {children}
    </Box>
  );
};

export default PageTemplate;
