import { Box, Button, Divider } from "@mui/material";
import React from "react";

type SelectableListProps = {
  items: { name: string; id: string }[];
  selectedItem?: string;
  onSelectItem: (id: string) => void;
  onDeleteItem?: (id: string) => void;
  //TODO reorder?
};

const SelectableList = ({
  items,
  onSelectItem,
  selectedItem,
  onDeleteItem,
}: SelectableListProps) => {
  return (
    <Box>
      <Box
        sx={{
          maxHeight: 250,
          overflowY: "auto",
          border: "1px solid",
        }}
        mb={2}
      >
        {items.map((item) => (
          <React.Fragment key={item.id}>
            <Button
              size="small"
              disableElevation
              sx={{ width: "100%", justifyContent: "flex-start" }}
              onClick={() => onSelectItem(item.id)}
              disabled={selectedItem === item.id}
              // variant={selectedItem === item.id ? "contained" : "text"}
            >
              {item.name}
            </Button>
            <Divider />
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default SelectableList;
