import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import TcForm from "../../components/tc-form/TcForm";
import { FormSchema } from "./types";

interface TcFormDialogProps<T> {
  submitButtonText: string;
  defaultValue: T;
  schema: FormSchema;
  onSubmit: (formData: T) => Promise<void>;
  children: (onOpenDialog: () => void) => React.ReactNode;
}

const TcFormDialog = <T extends {}>({
  children,
  submitButtonText,
  defaultValue,
  onSubmit,
  schema,
}: TcFormDialogProps<T>) => {
  const [formData, setFormData] = useState(defaultValue);
  const [isOpen, setIsOpen] = useState(false);
  const onSubmitClick = useCallback(() => {
    console.log("formData", formData);
    onSubmit(formData).then(() => setIsOpen(false));
  }, [formData, onSubmit, setIsOpen]);
  const onRequestClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const onOpenDialog = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  return (
    <>
      {/* <Button onClick={onOpenDialog}>Edit Properties</Button> */}
      {children(onOpenDialog)}
      <Dialog open={isOpen} onClose={onRequestClose}>
        <DialogTitle>Region Properties</DialogTitle>
        <DialogContent>
          <Box sx={{ minWidth: 500 }}>
            <TcForm
              schema={schema}
              onFormChange={setFormData}
              defaultValues={defaultValue}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onRequestClose}>Cancel</Button>
          <Button onClick={onSubmitClick}>{submitButtonText}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TcFormDialog;
