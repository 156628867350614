import React from "react";
import { Popup } from "react-leaflet";
import { lngLatToLatLng } from "../../util/regionUtils";

type TextMarkerProps = {
  lngLat: [number, number];
  text: string;
};

const TextMarker = ({ lngLat, text }: TextMarkerProps) => {
  return (
    <Popup
      position={lngLatToLatLng(lngLat)}
      closeButton={false}
      autoClose={false}
      keepInView={false}
      autoPan={false}
      closeOnClick={false}
      closeOnEscapeKey={false}
    >
      {text}
    </Popup>
  );
};

export default TextMarker;
