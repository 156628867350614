import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DeviceApi, PartnerApi, PartnerConfigApi, RegionApi } from "../../lib";
import { RootState } from "../../store/store";

export const tcApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = state?.auth?.accessToken;
      headers.set("authorization", `Bearer ${token}`);
    },
  }),
  endpoints: (builder) => ({}),
});

const dashboardApi = tcApi
  .enhanceEndpoints({
    addTagTypes: ["getDashboard"],
  })
  .injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
      getDashboard: builder.query<
        PartnerApi.GetDashboardResponse,
        PartnerApi.GetDashboardRequest
      >({
        query: ({ partnerId }) => ({
          url: `api/dashboard`,
          params: { partnerId },
        }),
        providesTags: ["getDashboard"],
      }),
      getPlan: builder.query<PartnerApi.GetPlanResponse, void>({
        query: () => ({
          url: `api/plan`,
        }),
      }),
    }),
  });

const partnerApi = tcApi
  .enhanceEndpoints({
    addTagTypes: ["getDashboard"],
  })
  .injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
      createPartner: builder.mutation<
        PartnerApi.CreatePartnerResponse,
        PartnerApi.CreatePartnerRequest
      >({
        query: ({ payload }) => ({
          url: `api/partner`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["getDashboard"],
      }),
      createPartnerConfig: builder.mutation<
        PartnerConfigApi.CreatePartnerConfigResponse,
        PartnerConfigApi.CreatePartnerConfigRequest
      >({
        query: ({ payload, partnerId }) => ({
          url: `api/partner/${partnerId}/partnerConfig`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["getDashboard"],
      }),
      updatePartnerConfig: builder.mutation<
        PartnerConfigApi.UpdatePartnerConfigResponse,
        PartnerConfigApi.UpdatePartnerConfigRequest
      >({
        query: ({ payload, pathParams }) => ({
          url: `api/partner/${pathParams.partnerId}/partnerConfig/${pathParams.partnerConfigId}`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["getDashboard"],
      }),
      createRegion: builder.mutation<
        RegionApi.CreateRegionResponse,
        RegionApi.CreateRegionRequest
      >({
        query: ({ payload, partnerId }) => ({
          url: `api/partner/${partnerId}/region`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["getDashboard"],
      }),
      updateRegion: builder.mutation<
        RegionApi.UpdateRegionResponse,
        RegionApi.UpdateRegionRequest
      >({
        query: ({ payload, partnerId, regionId }) => ({
          url: `api/partner/${partnerId}/region/${regionId}`,
          method: "PUT",
          body: payload,
        }),
        invalidatesTags: ["getDashboard"],
      }),
      copyRegion: builder.mutation<
        RegionApi.CopyRegionResponse,
        RegionApi.CopyRegionRequest
      >({
        query: ({ partnerId, regionId }) => ({
          url: `api/partner/${partnerId}/region/${regionId}/copy`,
          method: "POST",
        }),
        invalidatesTags: ["getDashboard"],
      }),
      listRegions: builder.query<
        RegionApi.ListRegionsResponse,
        RegionApi.ListRegionsRequest
      >({
        query: ({ partnerId }) => ({
          url: `api/partner/${partnerId}/region`,
          method: "GET",
        }),
        providesTags: ["getDashboard"],
      }),
      updateDevice: builder.mutation<
        DeviceApi.UpdateDeviceResponse,
        DeviceApi.UpdateDeviceRequest
      >({
        query: ({ payload, partnerId, deviceId }) => ({
          url: `api/partner/${partnerId}/device/${deviceId}`,
          method: "PUT",
          body: payload,
        }),
        invalidatesTags: ["getDashboard"],
      }),
      createDevice: builder.mutation<
        DeviceApi.CreateDeviceResponse,
        DeviceApi.CreateDeviceRequest
      >({
        query: ({ payload, partnerId }) => ({
          url: `api/partner/${partnerId}/device`,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["getDashboard"],
      }),
      getDeviceDetail: builder.query<
        DeviceApi.GetDeviceResponse,
        DeviceApi.GetDeviceRequest
      >({
        query: ({ partnerId, deviceId }) => ({
          url: `api/partner/${partnerId}/device/${deviceId}`,
          method: "GET",
        }),
      }),
      getDeviceLog: builder.query<
        DeviceApi.GetDeviceLogResponse,
        DeviceApi.GetDeviceLogRequest
      >({
        query: ({ partnerId, deviceId, date }) => ({
          url: `api/partner/${partnerId}/device/${deviceId}/log/${date}`,
          method: "GET",
        }),
      }),
      unregisterDevice: builder.mutation<
        {},
        { deviceId: string; partnerId: string }
      >({
        query: ({ partnerId, deviceId }) => ({
          url: `api/partner/${partnerId}/device/${deviceId}/unregister`,
          method: "POST",
        }),
      }),
    }),
  });

export const { useGetDashboardQuery, useLazyGetPlanQuery, useGetPlanQuery } =
  dashboardApi;
export const {
  useCreatePartnerMutation,
  useCreateDeviceMutation,
  useCreatePartnerConfigMutation,
  useCreateRegionMutation,
  useUpdateRegionMutation,
  useCopyRegionMutation,
  useGetDeviceDetailQuery,
  useListRegionsQuery,
  useUpdateDeviceMutation,
  useLazyGetDeviceLogQuery,
  useUpdatePartnerConfigMutation,
  useUnregisterDeviceMutation,
} = partnerApi;
