import { Box, debounce, Paper, TextField } from "@mui/material";
import React, { ChangeEvent, useCallback, useState } from "react";
import Barcode from "react-barcode";
import { useSearchParams } from "react-router-dom";

const BarcodePage = () => {
  const [searchParams] = useSearchParams();
  const barcodeFromParam = searchParams.get("code") || "";
  const [barcodeText, setBarcodeText] = useState("");

  const onChangeBarcode = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setBarcodeText(newValue);
    }, 500),
    [setBarcodeText]
  );

  const barcode = barcodeText || barcodeFromParam || "default";
  return (
    <Paper sx={{ minHeight: "100vh", width: "100%", pt: 8 }}>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Barcode value={barcode} />
        <Box sx={{ mt: 1 }}>
          <TextField
            label="Barcode Data"
            defaultValue={barcodeFromParam}
            onChange={onChangeBarcode}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default BarcodePage;
