import React, { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import Box from "@mui/material/Box";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import { useParams, useSearchParams } from "react-router-dom";
import {
  useLazyGetPartnerConfigQuery,
  useLazyGetRegionConfigQuery,
} from "../../util/client/ConfigApi";
import { Drt } from "../../lib/Drt";
import MapDisplay from "./MapDisplay";
import TrailList from "./TrailList";
import { reorderArray } from "../../util/regionUtils";
import PoiDialog from "./PoiDialog";
import GeometryEditor from "./GeometryEditor";
import { Fab, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useLazyGetDeviceLogQuery } from "../../util/client/TcApi";
import { DeviceApi } from "../../lib";
import dayjs from "dayjs";
import RegionUtils from "../../lib/RegionUtils";

type MapPageProps = {
  isEditMode: boolean;
  deviceHistory?: DeviceApi.GetDeviceLogResponse;
};

type PoiDialogInfo = {
  trailId: string;
  geometryId: string;
};

const MapPage = ({ isEditMode, deviceHistory }: MapPageProps) => {
  const { regionId, partnerId } = useParams();

  const [selectedTrailId, setSelectedTrailId] = useState<string | undefined>(
    undefined
  );
  const [selectedTrailGeometryId, setSelectedTrailGeometryId] = useState<
    string | undefined
  >(undefined);

  const [addMarkerTrailId, setAddMarkerTrailId] = useState<string | undefined>(
    undefined
  );
  const [poiDialogOpen, setPoiDialogOpen] = useState<PoiDialogInfo | undefined>(
    undefined
  );
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [selectedHistoryIndex, setSelectedHistoryIndex] = useState<
    number | undefined
  >(undefined);
  const [region, setRegion] = useState<Drt.Region | undefined>(undefined);
  const [getRegionConfig, regionConfigResult] = useLazyGetRegionConfigQuery();
  const { data: regionFromConfigApi, isFetching: isFetchingRegionConfig } =
    regionConfigResult;

  const [getPartnerConfig, partnerConfigResult] =
    useLazyGetPartnerConfigQuery();
  const { data: partnerConfig, isFetching: isFetchingPartnerConfig } =
    partnerConfigResult;

  useEffect(() => {
    if (regionId) {
      getRegionConfig(regionId);
    }
  }, [regionId, getRegionConfig]);
  useEffect(() => {
    if (regionFromConfigApi && !region) {
      //TODO remove this
      const clone = JSON.parse(
        JSON.stringify(regionFromConfigApi)
      ) as Drt.Region;
      if (!clone.type) {
        clone.type = "FeatureCollection";
      }
      clone.features.forEach((trail) => {
        if (!trail.type) {
          trail.type = "Feature";
        }
      });
      setRegion(clone);
      // setRegion(regionFromConfigApi);
      getPartnerConfig(regionFromConfigApi.properties.partnerConfigId);
    }
  }, [regionFromConfigApi, region, setRegion, getPartnerConfig]);

  const onEditRegionProps = useCallback(
    (
      regionProps: Drt.RegionProperties & {
        regionName: string;
      }
    ) => {
      const newRegion = JSON.parse(JSON.stringify(region)) as Drt.Region;
      const { regionName, ...rest } = regionProps;
      newRegion.properties = {
        ...newRegion.properties,
        ...rest,
      };
      newRegion.name = regionName;
      setRegion(newRegion);
    },
    [region, setRegion]
  );
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const onDrawerClose = useCallback(() => {
    setDrawerOpen(false);
  }, [setDrawerOpen]);
  const onDrawerOpen = useCallback(() => {
    setDrawerOpen(true);
    setSelectedTrailId(undefined);
    setSelectedTrailGeometryId(undefined);
  }, [setDrawerOpen]);

  const extractGeometryToTrail = useCallback(
    (
      sourceTrailId: string,
      sourceGeometryId: string,
      targetTrailId?: string
    ) => {
      if (!region) return;
      const regionCopy = RegionUtils.copyRegion(region);
      const trailId = RegionUtils.extractGeometryToNewTrail(
        regionCopy,
        sourceTrailId,
        sourceGeometryId,
        targetTrailId
      );
      // TODO select new trail
      setRegion(regionCopy);
      if (trailId) {
        setSelectedTrailId(trailId);
      }
    },
    [region, setRegion]
  );

  const selectedTrail = useMemo(
    () =>
      region?.features.find((trail) => trail.properties.id === selectedTrailId),
    [selectedTrailId, region]
  );

  const selectedTrailGeometry = useMemo(
    () =>
      selectedTrail?.geometry?.geometries?.find(
        (geom) => geom.properties.id === selectedTrailGeometryId
      ),
    [selectedTrail, region, selectedTrailGeometryId]
  );

  const trailList = useMemo(
    () =>
      region?.features.map(({ properties: { id, name } }) => ({ id, name })) ||
      [],
    [region]
  );

  if (
    !region ||
    !partnerConfig ||
    isFetchingRegionConfig ||
    isFetchingPartnerConfig
  ) {
    return <h1>Loading...</h1>;
  }

  const poiTrail = region.features.find(
    (trail) => trail.properties.id === poiDialogOpen?.trailId
  );
  const poiGeometry = poiTrail?.geometry?.geometries?.find(
    (geom) => geom.properties.id === poiDialogOpen?.geometryId
  );

  const onViewTrailClick = (trailId: string | undefined) => {
    setSelectedTrailId(trailId);
    setSelectedTrailGeometryId(undefined);
    if (!isEditMode) {
      onDrawerClose();
    }
  };
  const onEditTrailProperties = (
    trailId: string,
    props: Partial<Drt.TrailProperties>
  ) => {
    if (!isEditMode) {
      return;
    }
    const newRegion = JSON.parse(JSON.stringify(region)) as Drt.Region;
    const trail = newRegion.features.find(
      (trail) => trail.properties.id === trailId
    );
    if (trail) {
      trail.properties = {
        ...trail.properties,
        ...props,
        id: trail.properties.id,
      };
      setRegion(newRegion);
    }
  };

  const onEditGeometryProperties = <T extends Drt.TrailGeometry>(
    geometryId: string,
    newProps: Partial<T["properties"]>
  ) => {
    if (!isEditMode) {
      return;
    }
    if (selectedTrail) {
      const newRegion = JSON.parse(JSON.stringify(region)) as Drt.Region;
      const trail = newRegion.features.find(
        (trail) => trail.properties.id === selectedTrail.properties.id
      );
      const geom = trail?.geometry?.geometries?.find(
        (geom) => geom.properties.id === geometryId
      );
      if (geom) {
        geom.properties = {
          ...geom.properties,
          ...newProps,
        };
        setRegion(newRegion);
      }
    }
  };
  const onDeleteTrail = (trailId: string) => {
    if (!isEditMode) {
      return;
    }
    const index = region.features.findIndex(
      (trail) => trail.properties.id === trailId
    );
    //never delete POI trail
    if (index >= 1) {
      const newRegion = JSON.parse(JSON.stringify(region)) as Drt.Region;
      newRegion.features.splice(index, 1);
      setRegion(newRegion);
    }
  };
  const onSelectTrailGeometry = (trailId?: string, geometryId?: string) => {
    if (!isEditMode || trailId !== selectedTrailId) {
      if (trailId && geometryId) {
        setPoiDialogOpen({ trailId, geometryId });
      } else {
        setPoiDialogOpen(undefined);
      }
      return;
    }
    if (!trailId || !geometryId) {
      setSelectedTrailGeometryId(undefined);
    } else {
      setSelectedTrailGeometryId(geometryId);
    }

    // const trail = region.features.find(
    //   (trail) => trail.properties.id === trailId
    // );
    // const geom = trail?.geometry?.geometries?.find(
    //   (geom) => geom.properties.id === geometryId
    // );
  };
  const onReorderTrail = (fromIndex: number, toIndex: number) => {
    if (!isEditMode) {
      return;
    }
    const newRegion = JSON.parse(JSON.stringify(region)) as Drt.Region;
    reorderArray(newRegion.features, fromIndex, toIndex);
    setRegion(newRegion);
  };
  const onDeleteTrailGeometry = (trailId: string, geometryId: string) => {
    if (!isEditMode) {
      return;
    }
    const newRegion = JSON.parse(JSON.stringify(region)) as Drt.Region;
    const trail = newRegion.features.find(
      (trail) => trail.properties.id === trailId
    );
    const geomIndex = trail?.geometry?.geometries?.findIndex(
      (geom) => geom.properties.id === geometryId
    );
    if (geomIndex !== undefined && geomIndex >= 0) {
      trail?.geometry?.geometries?.splice(geomIndex, 1);
      setRegion(newRegion);
    }
  };

  const onUpdateCoordinates = <T extends Drt.TrailGeometry>(
    geometry: T,
    newCoords: T["coordinates"]
  ) => {
    if (!isEditMode) {
      return;
    }
    if (selectedTrail) {
      const newRegion = JSON.parse(JSON.stringify(region)) as Drt.Region;
      const trail = newRegion.features.find(
        (trail) => trail.properties.id === selectedTrail.properties.id
      );
      const geom = trail?.geometry?.geometries?.find(
        (geom) => geom.properties.id === geometry.properties.id
      );
      if (geom) {
        geom.coordinates = newCoords;
        setRegion(newRegion);
      }
    }
  };

  const onAddTrail = (trail: Drt.Trail) => {
    if (!isEditMode) {
      return;
    }
    const newRegion = JSON.parse(JSON.stringify(region)) as Drt.Region;
    newRegion.features.push(trail);
    setRegion(newRegion);
    setSelectedTrailId(trail.properties.id);
  };
  const onAddMarker = (trailId: string) => {
    setAddMarkerTrailId(trailId);
  };

  const onAddMarkerToTrail = (
    trailId: string,
    coordinates: Drt.PointOfInterest["coordinates"]
  ) => {
    const newRegion = JSON.parse(JSON.stringify(region)) as Drt.Region;
    const trail = newRegion.features.find(
      (trl) => trl.properties.id === trailId
    );
    if (trail) {
      const newPoint: Drt.PointOfInterest = {
        type: "Point",
        coordinates,
        properties: { ...partnerConfig.defaultPois[0], id: uuid() },
      };
      trail.geometry.geometries.push(newPoint);
      setRegion(newRegion);
      setSelectedTrailGeometryId(newPoint.properties.id);
      setAddMarkerTrailId(undefined);
    }
  };

  return (
    <>
      <TrailList
        region={region}
        regionId={regionId!}
        partnerId={partnerId!}
        onViewTrailClick={onViewTrailClick}
        selectedTrailId={selectedTrail?.properties?.id}
        isEditMode={isEditMode}
        onEditTrailProperties={onEditTrailProperties}
        onDeleteTrail={onDeleteTrail}
        onSelectTrailGeometry={onSelectTrailGeometry}
        onReorderTrail={onReorderTrail}
        selectedGeometryId={selectedTrailGeometryId}
        onDeleteTrailGeometry={onDeleteTrailGeometry}
        onAddTrail={onAddTrail}
        onAddMarker={onAddMarker}
        onEditRegionProps={onEditRegionProps}
        onDrawerClose={onDrawerClose}
        isDrawerOpen={drawerOpen}
        deviceHistory={deviceHistory}
        selectHistoryItem={setSelectedHistoryIndex}
        selectedHistoryIndex={selectedHistoryIndex}
      />

      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "100%",
          marginLeft: isDesktop ? "450px" : 0,
        }}
      >
        <Toolbar />
        <Box sx={{ flexGrow: 1, minHeight: 500, position: "relative" }}>
          <Fab
            color="secondary"
            aria-label="add"
            size="medium"
            onClick={onDrawerOpen}
            sx={{
              position: "absolute",
              top: 8,
              left: 8,
              display: { sm: "none" },
            }}
          >
            <ChevronRightIcon />
          </Fab>
          <MapContainer
            center={[38.5706383, -109.5388723]}
            zoom={13}
            minZoom={5}
            id="leaflet-map"
            zoomControl={false}
          >
            <ZoomControl position="topright" />
            <TileLayer
              attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
              url="https://trailcompanion.app/api/tile/{z}/{x}/{y}"
            />
            {/* TODO show instructions to click screen. add callback for onAddMarkerToTrail that adds and selects the new marker to the trail */}
            <MapDisplay
              region={region}
              partnerConfig={partnerConfig}
              selectedTrail={selectedTrail}
              addMarkerTrailId={addMarkerTrailId}
              selectedTrailGeometryId={
                isEditMode ? selectedTrailGeometryId : undefined
              }
              onSelectTrailGeometry={onSelectTrailGeometry}
              onAddMarkerToTrail={onAddMarkerToTrail}
              onUpdateCoordinates={onUpdateCoordinates}
              deviceHistory={deviceHistory}
              selectHistoryItem={setSelectedHistoryIndex}
              selectedHistoryIndex={selectedHistoryIndex}
            />
          </MapContainer>
        </Box>
        <PoiDialog
          selectedGeometry={poiGeometry}
          onRequestClose={() => setPoiDialogOpen(undefined)}
        />
        {isEditMode && (
          <GeometryEditor
            geometry={selectedTrailGeometry}
            defaultPois={partnerConfig.defaultPois}
            onEditGeometryProperties={onEditGeometryProperties}
            onDeleteTrailGeometry={onDeleteTrailGeometry}
            selectedTrailId={selectedTrailId}
            extractGeometryToTrail={extractGeometryToTrail}
            trailList={trailList}
          />
        )}
      </Box>
    </>
  );
};
export const EditMapPage = () => <MapPage isEditMode />;
export const ViewMapPage = () => <MapPage isEditMode={false} />;

export const ViewDeviceHistoryMapPage = () => {
  const { partnerId, deviceId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const date = searchParams.get("date");
  const [triggerGetDeviceLog, { data: deviceHistory }] =
    useLazyGetDeviceLogQuery();
  useEffect(() => {
    if (deviceId && date && partnerId) {
      triggerGetDeviceLog({ deviceId, date, partnerId });
    } else if (!date && deviceId) {
      setSearchParams(
        { date: dayjs().format("YYYY-MM-DD") },
        { replace: true }
      );
    }
  }, [deviceId, date, partnerId, setSearchParams, triggerGetDeviceLog]);
  return <MapPage isEditMode={false} deviceHistory={deviceHistory} />;
};
