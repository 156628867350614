import { Drt } from "../../lib/Drt";
import { tcApi } from "./TcApi";

const configApi = tcApi.injectEndpoints({
  endpoints: (builder) => ({
    getRegionConfig: builder.query<Drt.Region, string>({
      query: (regionId) => `config/region/${regionId}.geo.json`,
    }),
    getPartnerConfig: builder.query<Drt.PartnerConfig, string>({
      query: (partnerConfigId) => `config/partner/${partnerConfigId}.json`,
    }),
  }),
});

export const {
  useLazyGetRegionConfigQuery,
  useLazyGetPartnerConfigQuery,
  useGetPartnerConfigQuery,
} = configApi;
