import { Drt, getPoints } from "../../lib/Drt";
import CircleMarker from "./CircleMarker";
import TextMarker from "./TextMarker";
import TrailGeometry from "./TrailGeometry";

type TrailProps = {
  trail: Drt.Trail;
  mode: "full" | "overview" | "basic";
  selectedTrailGeometryId?: string;
  onGeometryClick?: (geometry: Drt.TrailGeometry) => void;
  onUpdateCoordinates?: <T extends Drt.TrailGeometry>(
    geometry: T,
    newCoords: T["coordinates"]
  ) => void;
};

const Trail = ({
  trail,
  mode,
  selectedTrailGeometryId,
  onGeometryClick,
  onUpdateCoordinates,
}: TrailProps) => {
  if (mode === "full" || trail.properties.name === "POI") {
    return (
      <>
        {trail.geometry.geometries.map((geom) => (
          <TrailGeometry
            geom={geom}
            key={geom.properties.id}
            isSelected={selectedTrailGeometryId === geom.properties.id}
            onGeometryClick={onGeometryClick}
            onUpdateCoordinates={onUpdateCoordinates}
          />
        ))}
      </>
    );
  } else if (mode === "overview") {
    const points = getPoints(trail.geometry.geometries);
    const trailPoint = points[Math.floor(points.length / 2)];
    return (
      <>
        {trail.geometry.geometries
          .filter(
            (geom) =>
              geom.type === "LineString" || geom.type === "MultiLineString"
          )
          .map((geom) => (
            <TrailGeometry
              geom={geom}
              key={geom.properties.id}
              isSelected={false}
            />
          ))}
        <TextMarker lngLat={trailPoint} text={trail.properties.name} />
      </>
    );
  } else if (mode === "basic") {
    const points = getPoints(trail.geometry.geometries);
    const trailPoint = points[Math.floor(points.length / 2)];
    return <CircleMarker lngLat={trailPoint} />;
  }
  return <></>;
};

export default Trail;
