import { Paper, Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
const HomePage = () => {
  return (
    <Paper sx={{ minHeight: "100vh", width: "100%", pt: 8 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: 2,
        }}
      >
        <Typography variant="h5" sx={{ maxWidth: 500 }}>
          Welcome to our members portal. From here you can{" "}
          <Link to="/login">Log in/Sign up</Link> or{" "}
          <a href="https://trail-companion.com">go to our main site</a>
        </Typography>
      </Box>
    </Paper>
  );
};

export default HomePage;
