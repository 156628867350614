import _ from "lodash";

export const permissionCategories = {
  partner: "partner",
  region: "region",
  device: "device",
  partnerConfig: "partnerConfig",
  user: "user",
} as const;
export const permissionActions = {
  create: "create",
  read: "read",
  update: "update",
  delete: "delete",
  updateRegion: "updateRegion",
} as const;
export const plans = {
  free: "free",
  custom: "custom",
  paid: "paid",
  individual: "individual",
} as const;
export declare namespace Permissions {
  type Plan = keyof typeof plans;
  type PermissionAction = keyof typeof permissionActions;
  type PermissionCategory = keyof typeof permissionCategories;
  type PermissionMap = { [key in PermissionCategory]: PermissionAction[] };
  type UserRole = "admin" | "partner" | "individual";
  type Perm = { category: PermissionCategory; perm: PermissionAction };
}

export const planPermissions: {
  [key in Permissions.Plan]: Permissions.PermissionMap;
} = {
  individual: {
    partner: ["read"],
    region: ["create", "read", "update", "delete"],
    device: [],
    partnerConfig: ["create", "read", "update", "delete"],
    user: [],
  },
  free: {
    partner: ["read"],
    region: ["create", "read", "update", "delete"],
    device: [],
    partnerConfig: ["create", "read", "update", "delete"],
    user: ["create", "delete"],
  },
  paid: {
    partner: ["read"],
    region: ["create", "read", "update", "delete"],
    device: ["read", "updateRegion"],
    partnerConfig: ["create", "read", "update", "delete"],
    user: ["create", "delete", "read"],
  },
  custom: {
    partner: ["read"],
    region: ["read", "update"],
    device: [],
    partnerConfig: ["read", "update"],
    user: [],
  },
};

export const PERMISSIONS: { [key: string]: Permissions.Perm } = {
  PARTNER_READ: { category: "partner", perm: "read" },
  PARTNER_CREATE: { category: "partner", perm: "create" },
  PARTNER_UPDATE: { category: "partner", perm: "update" },
  PARTNER_DELETE: { category: "partner", perm: "delete" },

  REGION_READ: { category: "region", perm: "read" },
  REGION_CREATE: { category: "region", perm: "create" },
  REGION_UPDATE: { category: "region", perm: "update" },
  REGION_DELETE: { category: "region", perm: "delete" },

  DEVICE_READ: { category: "device", perm: "read" },
  DEVICE_CREATE: { category: "device", perm: "create" },
  DEVICE_UPDATE: { category: "device", perm: "update" },
  DEVICE_DELETE: { category: "device", perm: "delete" },
  DEVICE_UPDATE_REGION: { category: "device", perm: "updateRegion" },

  PARTNER_CONFIG_READ: { category: "partnerConfig", perm: "read" },
  PARTNER_CONFIG_CREATE: { category: "partnerConfig", perm: "create" },
  PARTNER_CONFIG_UPDATE: { category: "partnerConfig", perm: "update" },
  PARTNER_CONFIG_DELETE: { category: "partnerConfig", perm: "delete" },

  USER_READ: { category: "user", perm: "read" },
  USER_CREATE: { category: "user", perm: "create" },
  USER_UPDATE: { category: "user", perm: "update" },
  USER_DELETE: { category: "user", perm: "delete" },
} as const;

export const getPlanPermissions = (plan: Permissions.Plan) =>
  planPermissions[plan];

export const hasPermissionsFor = (
  role: Permissions.UserRole,
  plan: Permissions.Plan | undefined,
  { category, perm }: Permissions.Perm
) => {
  console.log("checking permissions for ", role, plan, category, perm);
  return (
    role === "admin" || (plan && planPermissions[plan][category].includes(perm))
  );
};
