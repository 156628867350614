import React, { useEffect, useMemo, useState } from "react";
import { useMapEvents } from "react-leaflet";
import Trail from "../../components/leaflet/Trail";
import { DeviceApi } from "../../lib";
import { Drt, getRegionBoundingBox, getTrailBoundingBox } from "../../lib/Drt";
import DeviceHistory from "./DeviceHistory";

type MapDisplayOptions = {
  region: Drt.Region;
  partnerConfig: Drt.PartnerConfig;
  selectedTrail?: Drt.Trail;
  selectedTrailGeometryId?: string;
  addMarkerTrailId?: string;
  deviceHistory?: DeviceApi.GetDeviceLogResponse;
  onAddMarkerToTrail: (
    trailId: string,
    coordinates: Drt.PointOfInterest["coordinates"]
  ) => void;
  onSelectTrailGeometry: (trailId?: string, geometryId?: string) => void;
  onUpdateCoordinates: <T extends Drt.TrailGeometry>(
    geometry: T,
    newCoords: T["coordinates"]
  ) => void;
  selectedHistoryIndex?: number;
  selectHistoryItem: (index: number) => void;
};

const getTrailDisplayMode = (currentZoom: number, region: Drt.Region) => {
  if (currentZoom >= (region.properties?.clusterZoomBreakpoint || 0)) {
    return "overview";
  } else {
    return "basic";
  }
};

const MapDisplay = ({
  region,
  selectedTrail,
  selectedTrailGeometryId,
  addMarkerTrailId,
  onAddMarkerToTrail,
  onSelectTrailGeometry,
  onUpdateCoordinates,
  deviceHistory,
  selectHistoryItem,
  selectedHistoryIndex,
}: MapDisplayOptions) => {
  const [currentZoom, setCurrentZoom] = useState<number | undefined>(undefined);
  const leafletMap = useMapEvents({
    zoomend: (event) => {
      setCurrentZoom(leafletMap.getZoom());
    },
    click: (event) => {
      if (addMarkerTrailId) {
        const { lat, lng } = event.latlng;

        onAddMarkerToTrail(addMarkerTrailId, [lng, lat]);
      } else {
        onSelectTrailGeometry(selectedTrail?.properties.id, undefined);
      }
    },
  });
  const regionBounds = useMemo(
    () => JSON.stringify(getRegionBoundingBox(region)),
    [region]
  );
  const selectedTrailBounds = useMemo(
    () =>
      selectedTrail &&
      JSON.stringify(getTrailBoundingBox(selectedTrail, false)),
    [selectedTrail]
  );
  useEffect(() => {
    if (deviceHistory && selectedHistoryIndex !== undefined) {
      const { lat, lon } = deviceHistory[selectedHistoryIndex];
      leafletMap.panTo([lat, lon]);
    }
  }, [selectedHistoryIndex, leafletMap, deviceHistory]);
  useEffect(() => {
    const bounds = JSON.parse(regionBounds);
    leafletMap.fitBounds(
      [
        [bounds.topLeft.lat, bounds.topLeft.lng],
        [bounds.bottomRight.lat, bounds.bottomRight.lng],
      ],
      { padding: [32, 32] }
    );
  }, [regionBounds, leafletMap]);

  useEffect(() => {
    if (selectedTrailBounds) {
      const bounds = JSON.parse(selectedTrailBounds);
      if (bounds.topLeft.lat === 360) {
        const rBounds = JSON.parse(regionBounds);
        leafletMap.flyToBounds(
          [
            [rBounds.topLeft.lat, rBounds.topLeft.lng],
            [rBounds.bottomRight.lat, rBounds.bottomRight.lng],
          ],
          { padding: [32, 32] }
        );
      } else {
        leafletMap.flyToBounds(
          [
            [bounds.topLeft.lat, bounds.topLeft.lng],
            [bounds.bottomRight.lat, bounds.bottomRight.lng],
          ],
          { padding: [32, 32] }
        );
      }
    }
  }, [selectedTrailBounds, leafletMap, regionBounds]);
  const poiTrail = region.features[0];
  if (currentZoom) {
    if (selectedTrail) {
      const isPoi = selectedTrail.properties.name === "POI";
      return (
        <>
          {deviceHistory && (
            <DeviceHistory
              history={deviceHistory}
              selectedIndex={selectedHistoryIndex}
              selectHistoryItem={selectHistoryItem}
            />
          )}
          {isPoi ? (
            region.features
              .filter((t) => t.properties.name !== "POI")
              .map((trail) => (
                <Trail
                  trail={trail}
                  mode="overview"
                  key={trail.properties.id}
                />
              ))
          ) : (
            <Trail
              trail={poiTrail}
              mode="overview"
              onGeometryClick={(geometry: Drt.TrailGeometry) =>
                onSelectTrailGeometry(
                  poiTrail.properties.id,
                  geometry.properties.id
                )
              }
            />
          )}
          <Trail
            trail={selectedTrail}
            mode="full"
            selectedTrailGeometryId={selectedTrailGeometryId}
            onGeometryClick={(geometry: Drt.TrailGeometry) =>
              onSelectTrailGeometry(
                selectedTrail.properties.id,
                geometry.properties.id
              )
            }
            onUpdateCoordinates={onUpdateCoordinates}
          />
        </>
      );
    } else {
      const mode = getTrailDisplayMode(currentZoom, region);
      return (
        <>
          {deviceHistory && (
            <DeviceHistory
              history={deviceHistory}
              selectedIndex={selectedHistoryIndex}
              selectHistoryItem={selectHistoryItem}
            />
          )}
          {region.features.map((trail) => (
            <Trail
              trail={trail}
              mode={mode}
              key={trail.properties.id}
              onGeometryClick={(geometry: Drt.TrailGeometry) =>
                onSelectTrailGeometry(
                  trail.properties.id,
                  geometry.properties.id
                )
              }
            />
          ))}
        </>
      );
    }
  }
  return <></>;
};

export default MapDisplay;
