import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import TcForm from "../../components/tc-form/TcForm";
import TcFormDialog from "../../components/tc-form/TcFormDialog";
import { regionPropertiesForm } from "../../forms/regionPropertiesForm";
import { Drt } from "../../lib";

type EditRegionPropertiesProps = {
  regionProps: Drt.RegionProperties & {
    regionName: string;
  };
  onSubmitChanges: (
    regionProps: Drt.RegionProperties & {
      regionName: string;
    }
  ) => void;
};

const EditRegionPropertiesButton = ({
  regionProps,
  onSubmitChanges,
}: EditRegionPropertiesProps) => {
  return (
    <TcFormDialog<EditRegionPropertiesProps["regionProps"]>
      submitButtonText="Done"
      defaultValue={{
        navModeTilt: 60,
        navModeZoom: 16,
        ...regionProps,
      }}
      schema={regionPropertiesForm}
      onSubmit={(formData) => {
        onSubmitChanges(formData);
        return Promise.resolve();
      }}
    >
      {(onOpenDialog) => (
        <Button onClick={onOpenDialog}>Edit Properties</Button>
      )}
    </TcFormDialog>
  );
};

export default EditRegionPropertiesButton;
