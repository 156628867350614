import { Box, Typography } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { PartnerApi } from "../../lib";
import CreatePartner from "./CreatePartner";
import CreateUser from "./CreateUser";
import PartnerCard from "./PartnerCard";
import UserCard from "./UserCard";

type AdminDashboardProps = PartnerApi.AdminGetDashboardResponse & {
  onRefresh: () => void;
};

const AdminDashboard = ({ partners, users }: AdminDashboardProps) => {
  const navigate = useNavigate();
  const selectPartner = useCallback(
    (id: string) => {
      navigate(`/dashboard/${id}`);
    },
    [navigate]
  );
  return (
    <Box>
      <Typography variant="h3">Partners</Typography>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        {partners.map((partner) => (
          <PartnerCard
            partner={partner}
            key={partner.id}
            onClick={() => selectPartner(partner.id)}
          />
        ))}
        <CreatePartner />
      </Box>
      <Typography variant="h3">Users</Typography>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        {users.map((user) => (
          <UserCard user={user} key={user.id} />
        ))}
        <CreateUser />
      </Box>
      {/* TODO - tools section with barcode tool and more? */}
    </Box>
  );
};

export default AdminDashboard;
