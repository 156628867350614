import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { getMarkerIcon } from "../../components/leaflet/IconMarker";
import * as Icons from "@mui/icons-material";
import TcForm from "../../components/tc-form/TcForm";
import { markerIconForm } from "../../forms/partnerConfigForm";
import { Drt } from "../../lib";
import _ from "lodash";

const iconOptions = _.keys(Icons).filter(
  (iconName) =>
    !iconName.endsWith("Rounded") &&
    !iconName.endsWith("TwoTone") &&
    !iconName.endsWith("Outlined") &&
    !iconName.endsWith("Sharp")
);

type MuiIcon = keyof typeof Icons;

const TcIcon = ({ name, ...props }: any & { name: string }) => {
  const MaybeIcon = Icons[name as MuiIcon];
  if (MaybeIcon) {
    return <MaybeIcon {...props} />;
  } else {
    return (
      <Box {...props}>
        <i className={`fa fa-${name}`}></i>
      </Box>
    );
  }
  //TODO FA icon
  // return <Icons.QuestionMark {...props} />;
};

const MuiMarker = ({
  size,
  iconProps,
}: {
  size: number;
  iconProps: Drt.PointOfInterestProperties;
}) => {
  const { iconColor, icon, pinColor, pinBorderColor } = iconProps;
  const borderSize = size / 16;
  const innerProps = { transform: "rotate(-45deg)", color: iconColor };
  // TODO find out how to index objects by prop name in kotlin
  // TODO fontawesome icons
  // TODO leaflet version of mui icons
  // TODO switch component in FormItem
  // const IconComponent = Icons[icon as MuiIcon] || Icons.QuestionMark;
  return (
    <Box
      sx={{
        background: pinColor,
        transform: "rotate(45deg)",
        borderRadius: `${size}px ${size}px 0px ${size}px`,
        width: `${size}px`,
        height: `${size}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        pointerEvents: "none",
        borderColor: `${pinBorderColor}`,
        borderWidth: `${borderSize}px`,
        borderStyle: "solid",
        fontSize: `${size * 0.66}px`,
      }}
    >
      <TcIcon sx={innerProps} fontSize="inherit" name={icon} />
      {/* <IconComponent sx={innerProps} fontSize="inherit" /> */}
    </Box>
  );
};

const SandboxPage = () => {
  const [selectedPresetIndex, setSelectedPresetIndex] = useState<number>(0);
  const [partnerConfig, setPartnerConfig] = useState<Drt.PartnerConfig>({
    defaultPois: [
      {
        icon: "Route",
        iconColor: "#ffffff",
        pinColor: "#0088ff",
        pinBorderColor: "#000000",
        text: "Starting point of a trail",
        id: "Start",
        renderLocationQr: true,
      },
      {
        icon: "Route",
        iconColor: "#ffffff",
        pinColor: "#0088ff",
        pinBorderColor: "#000000",
        text: "Starting point of a trail",
        id: "Start",
        renderLocationQr: true,
      },
    ],
    faqs: [],
  });
  const onPresetChange = useCallback(
    (value: Drt.PointOfInterestProperties) => {
      const clone = JSON.parse(
        JSON.stringify(partnerConfig)
      ) as Drt.PartnerConfig;
      clone.defaultPois[selectedPresetIndex] = value;
      setPartnerConfig(clone);
    },
    [partnerConfig, selectedPresetIndex]
  );

  const marker = getMarkerIcon(
    {
      ...partnerConfig.defaultPois[selectedPresetIndex],
      icon: "location-arrow",
    },
    false,
    64
  ).options.html as string;
  return (
    <Box sx={{ disply: "flex", flexDirection: "column" }}>
      <Toolbar />
      <Typography>Sandbox</Typography>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={iconOptions}
        sx={{ width: 300 }}
        // renderOption={(props, option) => {
        //   const Icon = Icons[option as MuiIcon] || Icons.QuestionMark;
        //   return <Icon />;
        // }}
        renderInput={(params) => <TextField {...params} label="Movie" />}
      />
      {/* {marker} */}

      <TcForm
        schema={markerIconForm(true)}
        onFormChange={onPresetChange}
        value={partnerConfig.defaultPois[selectedPresetIndex]}
      />
      <div dangerouslySetInnerHTML={{ __html: marker }} />
      <Typography variant="h6">Select a preset below to change it</Typography>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        {partnerConfig.defaultPois.map((marker, index) => (
          <Button
            variant="contained"
            color="inherit"
            disabled={index === selectedPresetIndex}
            sx={{ pb: 2, width: 96, height: 96, m: 1 }}
            onClick={() => setSelectedPresetIndex(index)}
          >
            <MuiMarker size={64} iconProps={marker} />
          </Button>
        ))}
      </Box>
      {/* <MuiMarker size={64} {...formData} icon={formData.icon as MuiIcon} /> */}
    </Box>
  );
};

export default SandboxPage;
