import haversineDistance from "haversine-distance";
import { trailTemplate } from "../util/file-templates";
import { Drt } from "./Drt";

const findTrailIndex = (region: Drt.Region, trailId: string): number =>
  region.features.findIndex((trail) => trail.properties.id === trailId);

const findTrail = (
  region: Drt.Region,
  trailId: string
): Drt.Trail | undefined =>
  region.features.find((trail) => trail.properties.id === trailId);

const findGeometryIndex = (trail: Drt.Trail, geometryId: string): number =>
  trail.geometry.geometries.findIndex(
    (geom) => geom.properties.id === geometryId
  );

const copyRegion = (region: Drt.Region): Drt.Region =>
  JSON.parse(JSON.stringify(region));

const extractGeometryToNewTrail = (
  region: Drt.Region,
  sourceTrailId: string,
  sourceGeometryId: string,
  destinationTrailId?: string
): string | undefined => {
  const trail = findTrail(region, sourceTrailId);
  if (!trail) {
    return;
  }
  const geometryIndex = findGeometryIndex(trail, sourceGeometryId);
  if (geometryIndex < 0) {
    return;
  }
  const newTrail = destinationTrailId
    ? findTrail(region, destinationTrailId)
    : trailTemplate();
  if (!newTrail) {
    return;
  }
  const [geometry] = trail.geometry.geometries.splice(geometryIndex, 1);
  newTrail.geometry.geometries.push(geometry);
  if (!destinationTrailId) {
    region.features.push(newTrail);
  }
  return newTrail.properties.id;
};

const calculateLineStringCoordinateLengthMeters = (
  ls: Drt.Path["coordinates"]
) => {
  return ls.reduce((acc, val, index, array) => {
    if (index === 0) {
      return acc;
    }
    const previousLngLat = array[index - 1];
    const previous = { lat: previousLngLat[1], lng: previousLngLat[0] };
    const [lng, lat] = val;
    const current = { lng, lat };
    const distance = haversineDistance(previous, current);
    return acc + distance;
  }, 0);
};

const calculateLineStringLengthMeters = (ls: Drt.Path) => {
  return calculateLineStringCoordinateLengthMeters(ls.coordinates);
};

const calculateMultiLineStringDistanceMeters = (mls: Drt.MultiPath) => {
  return mls.coordinates.reduce((acc, val) => {
    return acc + calculateLineStringCoordinateLengthMeters(val);
  }, 0);
};

const calculateTrailLengthMeters = (trail: Drt.Trail) => {
  const meters = trail.geometry.geometries.reduce((acc, geom) => {
    if (geom.type === "MultiLineString") {
      return acc + calculateMultiLineStringDistanceMeters(geom);
    } else if (geom.type === "LineString") {
      return acc + calculateLineStringLengthMeters(geom);
    }
    return acc;
  }, 0);

  return meters;
};

export default {
  findTrailIndex,
  findTrail,
  findGeometryIndex,
  copyRegion,
  extractGeometryToNewTrail,
  calculateTrailLengthMeters,
};
