import { Box } from "@mui/material";
import React, { ChangeEventHandler } from "react";
import TcFormItem from "./TcFormItem";
import { FormItem } from "./types";

type TcFormRowProps = {
  row: FormItem[];
  values: { [key: string]: any };
  onChange: (
    item: FormItem,
    propName: string,
    rawValue: string,
    checked?: boolean
  ) => void;
};

const TcFormRow = ({ row, values, onChange }: TcFormRowProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        my: 0.75,
      }}
    >
      {row.map((item, index) => {
        return (
          <Box
            key={index}
            sx={{
              flex: item.rowWeight || 1,
              mx: 0.25,
            }}
          >
            <TcFormItem
              item={item}
              value={values[item.propName]}
              onChange={onChange}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default TcFormRow;
