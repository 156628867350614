import { FormItem, FormSchema } from "../components/tc-form/types";

export const partnerConfigForm: FormSchema = [
  [{ type: "text", propName: "name", label: "Partner Config Name" }],
];

export const markerIconForm = (preset: boolean): FormSchema => [
  [{ type: "text", propName: "icon", label: "Icon" }],
  ...(preset
    ? ([
        [
          { type: "color", propName: "iconColor", label: "Icon Color" },
          { type: "color", propName: "pinColor", label: "Background Color" },
          { type: "color", propName: "pinBorderColor", label: "Border Color" },
        ],
      ] as FormItem[][])
    : ([
        [{ type: "color", propName: "iconColor", label: "Icon Color" }],
        [{ type: "color", propName: "pinColor", label: "Background Color" }],
        [{ type: "color", propName: "pinBorderColor", label: "Border Color" }],
      ] as FormItem[][])),
  ...(preset
    ? [
        [{ type: "text", propName: "id", label: "Preset Name" } as FormItem],
        [
          {
            type: "multiline",
            propName: "text",
            label: "Preset Description",
            rows: 4,
          } as FormItem,
        ],
      ]
    : [
        [
          {
            type: "multiline",
            propName: "text",
            label: "Popup Text",
            rows: 4,
          } as FormItem,
        ],
      ]),
  [
    {
      type: "switch",
      propName: "renderLocationQr",
      label: "Show Navigation QR",
    },
  ],
];
