import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useGetPlanQuery } from "../client/TcApi";

const usePermissions = () => {
  // const roleState = useSelector<RootState>((state) => state.auth.role);

  const queryData = useGetPlanQuery();
  // const { data, isFetching } = queryData;
  // if (!roleState && !data && !isFetching) {
  //   triggerGetPlan();
  // }
  return queryData;
};

export default usePermissions;
