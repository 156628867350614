import { DivIcon } from "leaflet";
import React from "react";
import { Marker } from "react-leaflet";
import { lngLatToLatLng } from "../../util/regionUtils";

const getMarkerIcon = (
  opacity: number = 1,
  markerSize: number = 16
): DivIcon => {
  const pinColor = "#D86E2D";
  const borderColor = "#000000";
  const html = `
    <div class="drt-marker" style="opacity: ${opacity}; background-color:${pinColor};border-radius: ${markerSize}px ${markerSize}px ${markerSize}px ${markerSize}px;width:${markerSize}px;height:${markerSize}px;display:flex;justify-content:center;align-items:center;pointer-events:none;border-color:${borderColor};border-width:2px;border-style:solid;">
      
    </div>
  `;
  const divIcon = new DivIcon({
    html: html,
    iconAnchor: [markerSize / 2, markerSize / 2],
    iconSize: [markerSize, markerSize],
  });
  return divIcon;
};

type CircleMarkerProps = {
  lngLat: [number, number];
  opacity?: number;
  markerSize?: number;
  onClick?: () => void;
  // pinColor: string;
  // pinBorderColor: string;
};

const CircleMarker = ({
  lngLat,
  opacity,
  markerSize,
  onClick,
}: CircleMarkerProps) => {
  return (
    <Marker
      position={lngLatToLatLng(lngLat)}
      icon={getMarkerIcon(opacity, markerSize)}
      eventHandlers={{ ...(onClick ? { click: onClick } : {}) }}
    />
  );
};

export default CircleMarker;
