import { MenuItem, Switch, TextField } from "@mui/material";
import { ChangeEvent, ChangeEventHandler, useCallback } from "react";
import { FormItem } from "./types";

type TcFormItemProps = {
  item: FormItem;
  value?: any;
  onChange: (
    item: FormItem,
    propName: string,
    rawValue: string,
    checked?: boolean
  ) => void;
};

const TcFormItem = ({ item, value, onChange }: TcFormItemProps) => {
  const onValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked?: boolean) => {
      const name = event.target.name;
      const newValueRaw = event.target.value;
      onChange(item, name, newValueRaw, checked);
    },
    [item, onChange]
  );

  if (
    item.type === "text" ||
    item.type === "email" ||
    item.type === "password" ||
    item.type === "color"
  ) {
    return (
      <TextField
        label={item.label}
        size="small"
        value={value || ""}
        type={item.type}
        required={item.required}
        fullWidth
        onChange={onValueChange}
        name={item.propName}
      />
    );
  } else if (item.type === "multiline") {
    return (
      <TextField
        label={item.label}
        size="small"
        value={value || ""}
        type="text"
        required={item.required}
        multiline
        rows={item.rows}
        fullWidth
        onChange={onValueChange}
        name={item.propName}
      />
    );
  } else if (item.type === "select") {
    return (
      <TextField
        label={item.label}
        size="small"
        value={value}
        required={item.required}
        onChange={onValueChange}
        fullWidth
        select
        name={item.propName}
      >
        {item.options.map((opt) => (
          <MenuItem value={opt.value} key={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </TextField>
    );
  } else if (item.type === "number") {
    return (
      <TextField
        label={item.label}
        size="small"
        value={value || 0}
        type="number"
        required={item.required}
        fullWidth
        onChange={onValueChange}
        name={item.propName}
      />
    );
  } else if (item.type === "switch") {
    return (
      <>
        <Switch
          value={value}
          checked={value}
          name={item.propName}
          onChange={onValueChange}
        />{" "}
        {item.label}
      </>
    );
  }
  return <>TODO {item.type}</>;
};

export default TcFormItem;
