import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
import { Outlet, Routes, Route, useNavigate, Link } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import {
  EditMapPage,
  ViewDeviceHistoryMapPage,
  ViewMapPage,
} from "./pages/map/MapPage";
import BarcodePage from "./pages/barcode/BarcodePage";
import LoginPage from "./pages/login/LoginPage";
import RequireAuth from "./util/auth/RequireAuth";
import awsExports from "./aws-exports";
import { Amplify } from "aws-amplify";
import DashboardPage from "./pages/dashboard/DashboardPage";
import { useAuthenticator } from "@aws-amplify/ui-react";
import AuthProvider from "./util/auth/AuthProvider";
import DeviceDetailPage from "./pages/device/DeviceDetailPage";
import { Permissions, PERMISSIONS } from "./lib";
import PermGuard from "./util/auth/PermGuard";
import SandboxPage from "./pages/sandbox/SandboxPage";
import PartnerConfigPage from "./pages/partner-config/PartnerConfigPage";
Amplify.configure(awsExports);

// router with auth example https://github.com/remix-run/react-router/tree/dev/examples/auth/src

const Layout = () => {
  const { signOut, route } = useAuthenticator((context) => [
    context.user,
    context.route,
  ]);
  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
              <Link
                to={route === "authenticated" ? "/dashboard" : "/"}
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                Trail Companion
              </Link>
            </Typography>
            {
              route === "authenticated" ? (
                <Button color="inherit" onClick={() => signOut()}>
                  Logout
                </Button>
              ) : undefined
              // <Button color="inherit" onClick={() => navigate("/login")}>
              //   Log in
              // </Button>
            }
          </Toolbar>
        </AppBar>
      </Box>
      <Box
        sx={{
          height: "100vh",
          maxHeight: "100vh",
          width: "100%",
          display: "flex",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
type Route = {
  path: string;
  requireLogin: boolean;
  Element: () => JSX.Element;
  perms?: Permissions.Perm;
};
const routes: Route[] = [
  { path: "/", requireLogin: false, Element: HomePage },
  { path: "/login", requireLogin: false, Element: LoginPage },
  { path: "/dashboard", requireLogin: true, Element: DashboardPage },
  { path: "/dashboard/:partnerId", requireLogin: true, Element: DashboardPage },
  {
    path: "/partner/:partnerId/map/:regionId",
    requireLogin: false,
    Element: ViewMapPage,
  },
  {
    path: "/partner/:partnerId/map/:regionId/device/:deviceId",
    requireLogin: true,
    Element: ViewDeviceHistoryMapPage,
    perms: PERMISSIONS.DEVICE_READ,
  },
  {
    path: "/partner/:partnerId/map/:regionId/edit",
    requireLogin: true,
    Element: EditMapPage,
    perms: PERMISSIONS.REGION_UPDATE,
  },
  { path: "/barcode", requireLogin: true, Element: BarcodePage },
  { path: "/sandbox", requireLogin: false, Element: SandboxPage },
  {
    path: "/partner/:partnerId/device/:deviceId",
    requireLogin: true,
    Element: DeviceDetailPage,
    perms: PERMISSIONS.DEVICE_UPDATE,
  },
  {
    path: "/partner/:partnerId/partner-config/:partnerConfigId",
    requireLogin: true,
    Element: PartnerConfigPage,
    perms: PERMISSIONS.PARTNER_CONFIG_EDIT,
  },
];
function App() {
  return (
    <AuthProvider>
      <Box sx={{ width: "100%", minHeight: "100%" }}>
        <Routes>
          <Route element={<Layout />}>
            {routes.map(({ path, requireLogin, Element, perms }) => (
              <Route
                key={path}
                path={path}
                element={
                  requireLogin ? (
                    <RequireAuth>
                      {perms ? (
                        <PermGuard perms={perms}>
                          <Element />
                        </PermGuard>
                      ) : (
                        <Element />
                      )}
                    </RequireAuth>
                  ) : (
                    <Element />
                  )
                }
              />
            ))}
          </Route>
        </Routes>
      </Box>
    </AuthProvider>
  );
}

export default App;
