import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setToken } from "./authSlice";
import { Auth } from "aws-amplify";

const AuthProvider = ({ children }: { children: JSX.Element }) => {
  return (
    <Authenticator.Provider>
      <AuthProviderInternal>{children}</AuthProviderInternal>
    </Authenticator.Provider>
  );
};

const AuthProviderInternal = ({ children }: { children: JSX.Element }) => {
  const { route } = useAuthenticator((context) => [context.route]);
  const dispatch = useDispatch();
  // const [triggerGetPlan, { data: planData, isFetching }] =
  //   useLazyGetPlanQuery();
  useEffect(() => {
    const token = setInterval(() => {
      Auth.currentSession()
        .then((session) => {
          const token = session?.getAccessToken()?.getJwtToken();
          // if (token && !planData && !isFetching) {
          //   triggerGetPlan();
          // }
          dispatch(setToken(token));
        })
        .catch(() => {
          dispatch(setToken(undefined));
        });
    }, 120000);
    return () => clearInterval(token);
  }, []);

  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        const token = session?.getAccessToken()?.getJwtToken();
        // if (token && !planData && !isFetching) {
        //   triggerGetPlan();
        // }
        dispatch(setToken(token));
      })
      .catch(() => {
        dispatch(setToken(undefined));
      });
  }, [route, dispatch]);

  return children;
};

export default AuthProvider;
