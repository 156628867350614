import {
  debounce,
  MenuItem,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import React, { ChangeEvent, useCallback, useRef } from "react";
import { Drt } from "../../lib/Drt";
import SpeedIcon from "@mui/icons-material/Speed";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import RouteIcon from "@mui/icons-material/Route";
import StraightenIcon from "@mui/icons-material/Straighten";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
import SelectableList from "../../components/SelectableList";
import RegionUtils from "../../lib/RegionUtils";

type TrailListItemProps = {
  trail: Drt.Trail;
  isSelected: boolean;
  isEditMode: boolean;
  selectedGeometryId?: string;
  onViewTrailClick: (trailId: string | undefined) => void;
  onEditTrailProperties: (
    trailId: string,
    props: Partial<Drt.TrailProperties>
  ) => void;
  onDeleteTrail: (trailId: string) => void;
  onDeleteTrailGeometry: (trailId: string, geometryId: string) => void;
  onSelectTrailGeometry: (trailId: string, geometryId: string) => void;
  onReorderTrail: (trailId: string, direction: number) => void;
  onAddMarker: (trailId: string) => void;
};
const trailTypeMap = {
  oab: "Out and Back",
  p2p: "Point to Point",
  loop: "Loop",
} as const;

const trailTypes = [
  { value: "oab", label: "Out and Back" },
  { value: "p2p", label: "Point to Point" },
  { value: "loop", label: "Loop" },
];

const TrailProperties = ({ props }: { props: Drt.TrailProperties }) => {
  return (
    <Box>
      <Typography variant="h5">{props.name}</Typography>
      <Typography variant="body1">{props.description}</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <SpeedIcon color="primary" />
            <Typography variant="body1">
              Difficulty: {props.trailDifficulty}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <TimelapseIcon color="primary" />
            <Typography variant="body1">
              Time: {props.completionTime}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <StraightenIcon color="primary" />
            <Typography variant="body1">Length: {props.trailLength}</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <RouteIcon color="primary" />
            <Typography variant="body1">
              {trailTypeMap[props.trailType]}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const EditableTrailProperties = ({
  props,
  trail,
  onEditTrailProperties,
}: {
  props: Drt.TrailProperties;
  trail: Drt.Trail;
  onEditTrailProperties: (
    trailId: string,
    props: Partial<Drt.TrailProperties>
  ) => void;
}) => {
  const onChangeName = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      const newProps = {
        name: newValue,
      };
      onEditTrailProperties(props.id, newProps);
    }, 1000),
    [props]
  );

  const onChangeDescription = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      const newProps = {
        description: newValue,
      };
      onEditTrailProperties(props.id, newProps);
    }, 1000),
    [props]
  );

  const onChangeType = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value as Drt.TrailType;
      const newProps = {
        trailType: newValue,
      };
      onEditTrailProperties(props.id, newProps);
    }, 1000),
    [props]
  );

  const onChangeCompletionTime = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      const newProps = {
        completionTime: newValue,
      };
      onEditTrailProperties(props.id, newProps);
    }, 1000),
    [props]
  );

  const onChangeDifficulty = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      const newProps = {
        trailDifficulty: newValue,
      };
      onEditTrailProperties(props.id, newProps);
    }, 1000),
    [props]
  );

  const onChangeLength = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      const newProps = {
        trailLength: newValue,
      };
      onEditTrailProperties(props.id, newProps);
    }, 1000),
    [props]
  );
  const lengthRef = useRef<TextFieldProps>(null);

  return (
    <Box>
      <TextField
        id="trail-name"
        sx={{ mb: 1 }}
        label="Trail Name"
        fullWidth
        defaultValue={props.name}
        onChange={onChangeName}
      />
      <TextField
        sx={{ mb: 1 }}
        label="Description"
        multiline
        fullWidth
        rows={4}
        defaultValue={props.description}
        onChange={onChangeDescription}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", mr: 0.5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mb: 1,
            }}
          >
            <SpeedIcon color="primary" />
            <TextField
              label="Difficulty"
              defaultValue={props.trailDifficulty}
              onChange={onChangeDifficulty}
              size="small"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mb: 1,
            }}
          >
            <TimelapseIcon color="primary" />
            <TextField
              label="Completion Time"
              defaultValue={props.completionTime}
              onChange={onChangeCompletionTime}
              size="small"
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", ml: 0.5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mb: 1,
            }}
          >
            <StraightenIcon color="primary" />
            <TextField
              label="Length"
              defaultValue={props.trailLength}
              onChange={onChangeLength}
              size="small"
              inputRef={lengthRef}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mb: 1,
            }}
          >
            <RouteIcon color="primary" />
            <TextField
              label="Trail Type"
              select
              fullWidth
              defaultValue={props.trailType}
              onChange={onChangeType}
              size="small"
            >
              {trailTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
      </Box>
      <Button
        onClick={() => {
          const distanceMeters = RegionUtils.calculateTrailLengthMeters(trail);
          const distanceMiles = (distanceMeters * 0.000621371).toFixed(2);
          if (lengthRef.current) {
            lengthRef.current.value = `${distanceMiles} miles`;
          }
          const newProps = {
            trailLength: `${distanceMiles} miles`,
          };
          onEditTrailProperties(props.id, newProps);
        }}
      >
        Calculate Distance
      </Button>
    </Box>
  );
};

const TrailListItem = ({
  trail,
  onViewTrailClick,
  isSelected,
  isEditMode,
  selectedGeometryId,
  onReorderTrail,
  onDeleteTrail,
  onSelectTrailGeometry,
  onDeleteTrailGeometry,
  onEditTrailProperties,
  onAddMarker,
}: TrailListItemProps) => {
  const selectTrailText = isSelected
    ? "Done"
    : isEditMode
    ? "Edit Trail"
    : "View Trail";
  const onSelectGeometry = (geometryId: string) => {
    onSelectTrailGeometry(trail.properties.id, geometryId);
  };
  const onDeleteGeometry = (geometryId: string) => {
    onDeleteTrailGeometry(trail.properties.id, geometryId);
  };

  return (
    <Card
      sx={{
        m: 1,
        p: 1,
        backgroundColor: isSelected ? "#f0f0f0" : "#ffffff",
      }}
    >
      {(!isEditMode || !isSelected) && (
        <TrailProperties props={trail.properties} />
      )}

      {isEditMode && isSelected && (
        <Box>
          <EditableTrailProperties
            props={trail.properties}
            onEditTrailProperties={onEditTrailProperties}
            trail={trail}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              p: 1,
            }}
          >
            <Typography variant="h6">Geometries</Typography>
            <Button
              variant="outlined"
              size="small"
              onClick={() => onAddMarker(trail.properties.id)}
            >
              <i className="fa-solid fa-location-dot"></i>+
            </Button>
          </Box>
          <SelectableList
            items={trail.geometry.geometries.map((geom) => ({
              id: geom.properties.id,
              name: `${geom.type}${geom.properties.id.substring(23)}`,
            }))}
            selectedItem={selectedGeometryId}
            onSelectItem={onSelectGeometry}
            onDeleteItem={onDeleteGeometry}
          />
        </Box>
      )}
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        {isEditMode && (
          <>
            <IconButton
              onClick={() => {
                onReorderTrail(trail.properties.id, -1);
              }}
              color="primary"
              disabled={trail.properties.name === "POI"}
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                onReorderTrail(trail.properties.id, 1);
              }}
              color="primary"
              disabled={trail.properties.name === "POI"}
            >
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton
              disabled={trail.properties.name === "POI"}
              onClick={() => {
                onDeleteTrail(trail.properties.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        )}
        <Button
          variant="contained"
          onClick={() =>
            onViewTrailClick(isSelected ? undefined : trail.properties.id)
          }
        >
          {selectTrailText}
        </Button>
      </Box>
    </Card>
  );
};

export default TrailListItem;
