import { Box, Card, Typography } from "@mui/material";
import React from "react";
import { useDropzone } from "react-dropzone";

type DropzoneCardProps = {
  text: string;
  onDrop: (acceptedFiles: File[]) => void;
};

const DropzoneCard = ({ text, onDrop }: DropzoneCardProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Card
      sx={{
        m: 1,
        p: 1,
        backgroundColor: "#ffffff",
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Box
        sx={{
          p: 3,
          border: "2px dashed",
          borderColor: isDragActive ? "#000000" : "transparent",
        }}
      >
        <Typography variant="h5">{text}</Typography>
      </Box>
    </Card>
  );
};

export default DropzoneCard;
