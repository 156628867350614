import _ from "lodash";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import CreateDevice from "./CreateDevice";
import CreatePartnerConfig from "./CreatePartnerConfig";
import CreateRegion from "./CreateRegion";
import DeviceCard from "./DeviceCard";
import RegionCard from "./RegionCard";
import RegionConfigCard from "./RegionConfigCard";
import { useMemo } from "react";
import { ApiCommon, PartnerApi, PERMISSIONS } from "../../lib";
import usePermissions from "../../util/auth/usePermissions";
import PermGuard from "../../util/auth/PermGuard";

type PartnerDashboardProps = PartnerApi.PartnerGetDashboardResponse & {
  onRefresh: () => void;
};

const PartnerDashboard = ({ partner, onRefresh }: PartnerDashboardProps) => {
  // const { data } = usePermissions();
  const { partnerId } = useParams();
  const navigate = useNavigate();
  const regions = useMemo(() => _.values(partner.regions), [partner]);
  const partnerConfigs = useMemo(
    () => _.values(partner.partnerConfigFiles),
    [partner]
  );
  const devices = useMemo(() => _.values(partner.devices), [partner]);
  return (
    <Box>
      {partnerId && (
        <Button onClick={() => navigate("/dashboard")}>Back</Button>
      )}
      {partner.plan !== "individual" ? (
        <Typography variant="h2">{partner.name}</Typography>
      ) : undefined}
      <PermGuard perms={PERMISSIONS.DEVICE_READ}>
        <Typography variant="h3">Devices</Typography>
        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          {devices.map((device: ApiCommon.Device) => (
            <DeviceCard
              device={device}
              key={device.id}
              partnerId={partner.id}
            />
          ))}
          <CreateDevice
            partnerId={partner.id}
            regions={_.values(partner.regions)}
            onRefresh={onRefresh}
          />
        </Box>
      </PermGuard>
      <Typography variant="h3">Regions</Typography>
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {regions.map((region: ApiCommon.RegionItem) => (
          <RegionCard
            region={region}
            partnerId={partner.id}
            key={region.id}
            onRefresh={onRefresh}
          />
        ))}
        <CreateRegion
          partnerId={partner.id}
          partnerConfigs={_.values(partner.partnerConfigFiles)}
        />
      </Box>
      <Typography variant="h3">Partner Configs</Typography>
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {partnerConfigs.map((partnerConfig: ApiCommon.PartnerConfigItem) => (
          <RegionConfigCard
            partnerConfig={partnerConfig}
            key={partnerConfig.id}
            partnerId={partner.id}
          />
        ))}
        <CreatePartnerConfig partnerId={partner.id} />
      </Box>
    </Box>
  );
};

export default PartnerDashboard;
