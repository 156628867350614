import React from "react";
import { Polyline } from "react-leaflet";
import { Drt } from "../../lib/Drt";
import { lngLatToLatLng } from "../../util/regionUtils";

type TrailPathProps = {
  path: Drt.TrailPath;
  isSelected: boolean;
  onGeometryClick?: (geometry: Drt.TrailGeometry) => void;
};
const TrailPath = ({ path, isSelected, onGeometryClick }: TrailPathProps) => {
  const pathOptions = isSelected
    ? { weight: 9, color: "#8800ff", draggable: true }
    : { weight: 5, color: "#0088ff" };
  const eventHandlers = {
    click: () => (onGeometryClick ? onGeometryClick(path) : undefined),
  };
  if (path.type === "LineString") {
    return (
      <Polyline
        pathOptions={pathOptions}
        positions={path.coordinates.map(lngLatToLatLng)}
        eventHandlers={eventHandlers}
      />
    );
  } else if (path.type === "MultiLineString") {
    return (
      <Polyline
        pathOptions={pathOptions}
        positions={path.coordinates.map((polyline) =>
          polyline.map(lngLatToLatLng)
        )}
        eventHandlers={eventHandlers}
      />
    );
  }
  return <></>;
};

export default TrailPath;
