import { Drt } from "../lib/Drt";

export const lngLatToLatLng = (
  lngLat: [number, number, number] | [number, number]
): [number, number] => {
  const [lng, lat] = lngLat;
  return [lat, lng];
};

// modifies in place
export const reorderArray = <T>(
  array: T[],
  fromIndex: number,
  toIndex: number
): void => {
  if (fromIndex !== toIndex) {
    const [item] = array.splice(fromIndex, 1);
    array.splice(toIndex, 0, item);
  }
};

export const getRegionHref = (region: Drt.Region) => {
  return `data:application/json;charset=utf-8,${encodeURIComponent(
    JSON.stringify(region)
  )}`;
};
