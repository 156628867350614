import { FormSchema, SelectFormItem } from "../components/tc-form/types";

export const createDeviceForm = (
  regionOptions: SelectFormItem["options"]
): FormSchema => [
  [{ type: "text", propName: "name", label: "Device Name" }],
  [{ type: "password", propName: "adminPassword", label: "Admin Password" }],
  [{ type: "text", propName: "hexnodeDeviceId", label: "Hexnode Device Id" }],
  [
    {
      type: "select",
      propName: "regionId",
      label: "Associated Region",
      options: regionOptions,
    },
  ],
];
