import { FormSchema } from "../components/tc-form/types";

export const regionPropertiesForm: FormSchema = [
  [{ type: "text", propName: "regionName", label: "Name" }],
  [
    {
      type: "select",
      propName: "tileCachingStrategy",
      label: "Cache Strategy",
      options: [
        { label: "All within bounds", value: "ALL_WITHIN_BOUNDS" },
        { label: "Around Points", value: "AROUND_POINTS" },
      ],
    },
  ],
  [
    { type: "number", propName: "minZoom", label: "Min Zoom", min: 5, max: 20 },
    { type: "number", propName: "maxZoom", label: "Max Zoom", min: 5, max: 20 },
    {
      type: "number",
      propName: "clusterZoomBreakpoint",
      label: "Cluster Breakpoint",
      min: 1,
      max: 20,
    },
  ],
  [
    {
      type: "number",
      propName: "navModeZoom",
      label: "Nav Mode Zoom",
      min: 5,
      max: 20,
    },
    /*
    https://developers.google.com/maps/documentation/android-sdk/reference/com/google/android/libraries/maps/model/CameraPosition.Builder#tilt(float)
    For zoom levels less than 10 the maximum is 30.
    For zoom levels from 10 to 14 the maximum increases linearly from 30 to 45 (e.g. at zoom level 12, the maximum is 37.5).
    For zoom levels from 14 to 15.5 the maximum increases linearly from 45 to 67.5.
    For zoom levels greater than 15.5 the maximum is 67.5.
    */
    {
      type: "number",
      propName: "navModeTilt",
      label: "Nav Mode Tilt",
      min: 0,
      max: 67.5,
    },
  ],
];
