import { Drt } from "../../lib/Drt";
import IconMarker from "./IconMarker";
import TrailPath from "./TrailPath";

type TrailGeometryProps = {
  geom: Drt.TrailGeometry;
  isSelected: boolean;
  onGeometryClick?: (geometry: Drt.TrailGeometry) => void;
  onUpdateCoordinates?: <T extends Drt.TrailGeometry>(
    geometry: T,
    newCoords: T["coordinates"]
  ) => void;
};

const TrailGeometry = ({
  geom,
  isSelected,
  onGeometryClick,
  onUpdateCoordinates,
}: TrailGeometryProps) => {
  if (geom.type === "Point") {
    return (
      <IconMarker
        marker={geom}
        isSelected={isSelected}
        onGeometryClick={onGeometryClick}
        onUpdateCoordinates={onUpdateCoordinates}
      />
    );
  } else if (geom.type === "LineString" || geom.type === "MultiLineString") {
    return (
      <TrailPath
        path={geom}
        isSelected={isSelected}
        onGeometryClick={onGeometryClick}
      />
    );
  }
  return <></>;
};

export default TrailGeometry;
