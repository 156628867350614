import { Card } from "@mui/material";

const DashboardCard = ({ children }: React.PropsWithChildren) => {
  return (
    <Card
      sx={{
        backgroundColor: "#ffffff",
        p: 1,
        m: 1,
        height: 150,
        minWidth: 150,
      }}
    >
      {children}
    </Card>
  );
};
export default DashboardCard;
