import {
  Box,
  Typography,
  Button,
  TextField,
  Autocomplete,
  Link,
} from "@mui/material";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageTemplate from "../../components/page/PageTemplate";
import TcForm from "../../components/tc-form/TcForm";
import { markerIconForm } from "../../forms/partnerConfigForm";
import { useGetPartnerConfigQuery } from "../../util/client/ConfigApi";
import * as Icons from "@mui/icons-material";
import { Drt } from "../../lib";
import ObjectUtils from "../../lib/ObjectUtils";
import { getMarkerIcon } from "../../components/leaflet/IconMarker";
import { useUpdatePartnerConfigMutation } from "../../util/client/TcApi";

const MuiMarker = ({
  size,
  iconProps,
}: {
  size: number;
  iconProps: Drt.PointOfInterestProperties;
}) => {
  const iconHtml = getMarkerIcon(iconProps, false, size).options.html as string;
  return <div dangerouslySetInnerHTML={{ __html: iconHtml }} />;
};

const PartnerConfigPage = () => {
  const { partnerConfigId, partnerId } = useParams();
  const [selectedPresetIndex, setSelectedPresetIndex] = useState<number>(0);
  const [partnerConfig, setPartnerConfig] = useState<
    Drt.PartnerConfig | undefined
  >(undefined);
  const { data: partnerConfigFromApi } = useGetPartnerConfigQuery(
    partnerConfigId as string
  );
  const [triggerUpdate, updateResult] = useUpdatePartnerConfigMutation();

  useEffect(() => {
    if (partnerConfigFromApi) {
      setPartnerConfig(partnerConfigFromApi);
    }
  }, [partnerConfigFromApi, setPartnerConfig]);

  const addPreset = useCallback(() => {
    const newPartnerConfig = ObjectUtils.deepCopy(partnerConfig);
    newPartnerConfig?.defaultPois?.push({
      icon: "Route",
      iconColor: "#ffffff",
      pinColor: "#0088ff",
      pinBorderColor: "#000000",
      text: "Starting point of a trail",
      id: "Start",
      renderLocationQr: true,
    });
    setSelectedPresetIndex(partnerConfig?.defaultPois?.length || 0);
    setPartnerConfig(newPartnerConfig);
  }, [partnerConfig]);

  const onPresetChange = useCallback(
    (value: Drt.PointOfInterestProperties) => {
      const clone = ObjectUtils.deepCopy(partnerConfig);
      if (clone) {
        clone.defaultPois[selectedPresetIndex] = value;
        setPartnerConfig(clone);
      }
    },
    [partnerConfig, selectedPresetIndex]
  );

  const onDeletePreset = useCallback(() => {
    const clone = ObjectUtils.deepCopy(partnerConfig);
    clone?.defaultPois?.splice(selectedPresetIndex, 1);
    setSelectedPresetIndex(selectedPresetIndex - 1);
    setPartnerConfig(clone);
  }, [
    partnerConfig,
    selectedPresetIndex,
    setSelectedPresetIndex,
    setPartnerConfig,
  ]);

  const onSavePartnerConfig = useCallback(() => {
    if (partnerConfig && partnerId && partnerConfigId) {
      triggerUpdate({
        payload: { partnerConfig },
        pathParams: { partnerId, partnerConfigId },
      });
    }
  }, [triggerUpdate, partnerConfig, partnerConfigId, partnerId]);

  if (!partnerConfig) {
    return <>Loading</>;
  }

  return (
    <PageTemplate hasAppBar={true}>
      <Typography variant="h5">Default Poi Icons</Typography>
      {/* <div dangerouslySetInnerHTML={{ __html: marker }} /> */}
      <Typography variant="h6">Select a preset below to change it</Typography>
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {partnerConfig.defaultPois.map((marker, index) => (
          <Button
            key={`marker_${index}`}
            variant="contained"
            color="inherit"
            disabled={index === selectedPresetIndex}
            sx={{ pb: 2, width: 96, height: 96, m: 1 }}
            onClick={() => setSelectedPresetIndex(index)}
          >
            <MuiMarker size={64} iconProps={marker} />
          </Button>
        ))}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Button sx={{ mx: 1 }} variant="text" onClick={onDeletePreset}>
          Delete Selected Preset
        </Button>
        <Button variant="outlined" onClick={addPreset}>
          Add Preset
        </Button>
      </Box>
      <Typography>
        You can use{" "}
        <Link href="https://fontawesome.com/v4/icons/" target="_blank">
          FontAwesome
        </Link>{" "}
        or{" "}
        <Link
          href="https://fonts.google.com/icons?icon.set=Material+Icons"
          target="_blank"
        >
          Material UI
        </Link>{" "}
        icon names
      </Typography>
      <TcForm
        schema={markerIconForm(true)}
        onFormChange={onPresetChange}
        value={partnerConfig.defaultPois[selectedPresetIndex]}
      />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Button variant="contained" onClick={onSavePartnerConfig}>
          Save
        </Button>
      </Box>
      {/* <Autocomplete
        disableListWrap
        options={iconOptions}
        sx={{ width: 300 }}
        // renderOption={(props, option) => {
        //   const Icon = Icons[option as MuiIcon] || Icons.QuestionMark;
        //   return <Icon />;
        // }}
        renderInput={(params) => <TextField {...params} label="Icon" />}
      /> */}
    </PageTemplate>
  );
};

export default PartnerConfigPage;
