import { FormSchema, SelectFormItem } from "../components/tc-form/types";

export const createRegionForm = (
  options: SelectFormItem["options"]
): FormSchema => [
  [{ type: "text", propName: "name", label: "Region Name" }],
  [
    {
      type: "select",
      propName: "partnerConfigId",
      label: "Partner Config",
      options,
    },
  ],
];
