import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import TcForm from "../../components/tc-form/TcForm";
import DashboardCard from "./DashboardCard";
import { createRegionForm } from "../../forms/createRegionForm";
import { useCreateRegionMutation } from "../../util/client/TcApi";
import { ApiCommon } from "../../lib";

type CreateRegionProps = {
  partnerId: string;
  partnerConfigs: ApiCommon.PartnerConfigItem[];
  // onPartnerResponse: (partner: TcApi.PartnerDetail) => void;
};

const CreateRegion = ({
  partnerId,
  partnerConfigs,
}: // onPartnerResponse,
CreateRegionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [triggerCreateRegion, createRegionResult] = useCreateRegionMutation();
  const { data, isSuccess, isLoading } = createRegionResult;
  console.log(createRegionResult);
  const [formData, setFormData] = useState({
    name: "",
    partnerConfigId: partnerConfigs[0]?.id,
  });
  const onSubmit = useCallback(() => {
    console.log("creating Region", formData);
    triggerCreateRegion({ partnerId, payload: formData }).then(() =>
      setIsOpen(false)
    );
  }, [formData, partnerId, triggerCreateRegion]);
  const onRequestClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const onOpenDialog = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const regionForm = useMemo(() => {
    const options = partnerConfigs.map((pc) => ({
      label: pc.name,
      value: pc.id,
    }));
    return createRegionForm(options);
  }, [partnerConfigs]);
  return (
    <>
      <DashboardCard>
        <Box
          onClick={onOpenDialog}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            width: "100%",
            height: "100%",
          }}
        >
          <AddIcon fontSize="large" />
          <Typography>Add Region</Typography>
        </Box>
      </DashboardCard>
      <Dialog open={isOpen} onClose={onRequestClose}>
        <DialogTitle>Create Region</DialogTitle>
        <DialogContent>
          <Box sx={{ minWidth: 500 }}>
            <TcForm
              schema={regionForm}
              onFormChange={setFormData}
              defaultValues={{
                name: "",
                partnerConfigId: partnerConfigs[0]?.id,
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onRequestClose}>Cancel</Button>
          <Button onClick={onSubmit} disabled={isLoading}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateRegion;
