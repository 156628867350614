import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useCallback, useMemo, useState } from "react";
import TcForm from "../../components/tc-form/TcForm";
import DashboardCard from "./DashboardCard";
import { useCreateDeviceMutation } from "../../util/client/TcApi";
import { createDeviceForm } from "../../forms/createDeviceForm";
import { ApiCommon, PERMISSIONS } from "../../lib";
import PermGuard from "../../util/auth/PermGuard";

type CreateDeviceProps = {
  partnerId: string;
  regions: ApiCommon.RegionItem[];
  onRefresh: () => void;
};

const CreateDevice = ({ partnerId, regions, onRefresh }: CreateDeviceProps) => {
  const [triggerCreateDevice /*, createDeviceResult*/] =
    useCreateDeviceMutation();
  const [formData, setFormData] = useState({
    name: "",
    regionId: regions[0]?.id,
    hexnodeDeviceId: 0,
    adminPassword: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const onSubmit = useCallback(() => {
    triggerCreateDevice({ partnerId, payload: formData }).then(() => {
      setIsOpen(false);
      onRefresh();
    });
  }, [formData, partnerId, triggerCreateDevice]);
  const onRequestClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const onOpenDialog = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const deviceForm = useMemo(() => {
    const options = regions.map((region) => ({
      label: region.name,
      value: region.id,
    }));
    return createDeviceForm(options);
  }, [regions]);
  return (
    <PermGuard perms={PERMISSIONS.DEVICE_CREATE}>
      <DashboardCard>
        <Box
          onClick={onOpenDialog}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            width: "100%",
            height: "100%",
          }}
        >
          <AddIcon fontSize="large" />
          <Typography>Add Device</Typography>
        </Box>
      </DashboardCard>
      <Dialog open={isOpen} onClose={onRequestClose}>
        <DialogTitle>Create Device</DialogTitle>
        <DialogContent>
          <Box sx={{ minWidth: 500 }}>
            <TcForm schema={deviceForm} onFormChange={setFormData} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onRequestClose}>Cancel</Button>
          <Button onClick={onSubmit}>Create</Button>
        </DialogActions>
      </Dialog>
    </PermGuard>
  );
};

export default CreateDevice;
