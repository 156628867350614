import { Box } from "@mui/material";
import { parse } from "path";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import TcFormRow from "./TcFormRow";
import { FormItem, FormSchema } from "./types";

type TcFormProps = {
  schema: FormSchema;
  defaultValues?: { [key: string]: any };
  value?: { [key: string]: any };
  onFormChange: (data: any) => void;
};

const TcForm = ({
  schema,
  defaultValues,
  onFormChange,
  value,
}: TcFormProps) => {
  const [internalValues, setInternalValues] = useState(defaultValues || {});
  const onChange = useCallback(
    (item: FormItem, propName: string, rawValue: string, checked?: boolean) => {
      let newValue: string | number | boolean = rawValue;
      if (item.type === "number") {
        newValue = parseFloat(rawValue);
        if (Number.isNaN(newValue)) {
          newValue = 0;
        }
      } else if (item.type === "switch") {
        newValue = !!checked;
      }
      const newValues = {
        ...(value ? value : internalValues),
        [propName]: newValue,
      };
      setInternalValues(newValues);
      onFormChange(newValues);
    },
    [internalValues, value]
  );
  return (
    <Box component="form" autoComplete="off">
      <Box sx={{ dislpay: "flex", flexDirection: "column" }}>
        {schema.map((row, index) => {
          return (
            <TcFormRow
              key={index.toString()}
              row={row}
              values={value || internalValues}
              onChange={onChange}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default TcForm;
