import { LatLngExpression } from "leaflet";
import _ from "lodash";
import React, { useMemo } from "react";
import { Polyline } from "react-leaflet";
import CircleMarker from "../../components/leaflet/CircleMarker";
import { DeviceApi } from "../../lib";

type Props = {
  history: DeviceApi.GetDeviceLogResponse;
  selectedIndex?: number;
  selectHistoryItem: (index: number) => void;
};

const splitPoints = (
  points: DeviceApi.GetDeviceLogResponse
): LatLngExpression[][] => {
  const sorted = _(points).uniq().sortBy("timestamp").value();

  return sorted.reduce((acc, historyItem, index) => {
    const { lat, lon, timestamp, locationAccuracy } = historyItem;
    const newItem = { lat, lng: lon, timestamp };
    if (locationAccuracy > 40) {
      return acc;
    }
    if (acc.length === 0) {
      acc.push([newItem]);
    } else {
      const lastGroup = acc[acc.length - 1];
      const lastItem = lastGroup[lastGroup.length - 1];
      const timePassed = (timestamp - lastItem.timestamp) / 1000;
      if (timePassed >= 60) {
        acc.push([newItem]);
      } else {
        lastGroup.push(newItem);
      }
    }
    return acc;
  }, [] as { lat: number; lng: number; timestamp: number }[][]);
};

const DeviceHistory = ({
  history,
  selectHistoryItem,
  selectedIndex,
}: Props) => {
  const paths = useMemo(() => {
    return splitPoints(history);
  }, [history]);
  return (
    <>
      <Polyline
        pathOptions={{ weight: 8, color: "#ff88ff" }}
        positions={paths}
      />
      {/* Uncomment below to show dots for every location update */}
      {/* {history
        .filter((item, index) => index % 20 === 0)
        .map((historyItem, index) => {
          return (
            <CircleMarker
              key={`marker_${index}`}
              lngLat={[historyItem.lon, historyItem.lat]}
              opacity={index === selectedIndex ? 1 : 0.5}
              markerSize={index === selectedIndex ? 16 : 12}
              onClick={() => {
                selectHistoryItem(index);
              }}
            />
          );
        })} */}
    </>
  );
};

export default DeviceHistory;
