import { GeoJSON } from "geojson";

export declare namespace Drt {
  type TrailType = "loop" | "oab" | "p2p";
  type PathDirection = "forwards" | "bidirectional" | "backwards";

  // type TrailConsumer = Offroader | Boater | ManPowered;
  // type Offroader = "Dirt Bike" | "Quad" | "UTV" | "Jeep";
  // type Boater = "Boat" | "Pontoon" | "Jet Ski";
  // type ManPowered = "Hike" | "Mountain Bike" | "Walk" | "Road Bike";

  interface RegionProperties {
    clusterZoomBreakpoint: number;
    tileCachingStrategy: "ALL_WITHIN_BOUNDS" | "AROUND_POINTS";
    minZoom: number;
    maxZoom: number;
    partnerConfigId: string;
    navModeZoom?: number;
    navModeTilt?: number;
  }

  interface Region extends GeoJSON.FeatureCollection {
    name: string;
    features: Trail[];
    properties: RegionProperties;
    crs: {
      type: "name";
      properties: {
        name: "urn:ogc:def:crs:OGC:1.3:CRS84";
      };
    };
  }
  interface Trail extends GeoJSON.Feature {
    properties: TrailProperties;
    geometry: TrailComponents;
  }
  interface TrailProperties {
    name: string;
    description: string;
    trailType: TrailType;
    completionTime: string; // "3-4 hours"
    trailLength: string; // "10 miles"
    trailDifficulty: string;
    // appropriateConsumers: TrailConsumer[];
    id: string;
  }
  interface TrailComponents extends GeoJSON.GeometryCollection {
    geometries: TrailGeometry[];
  }
  //Geometries
  type TrailGeometry = TrailPath | PointOfInterest;
  type TrailPath = MultiPath | Path;
  type PointOfInterest = Point;

  type PathProperties = {
    direction?: PathDirection;
    color?: string; // #0088FF
    id: string;
  };
  interface MultiPath extends GeoJSON.MultiLineString {
    properties: PathProperties;
    coordinates: [number, number][][];
  }
  interface Path extends GeoJSON.LineString {
    properties: PathProperties;
    coordinates: [number, number][];
  }

  type PointOfInterestProperties = {
    icon: string; //fontawesome icon
    iconColor?: string; // #0088FF
    pinColor?: string; // #0088FF
    pinBorderColor?: string; // #0088FF
    text?: string;
    renderLocationQr?: boolean;
    id: string;
  };

  interface Point extends GeoJSON.Point {
    properties: PointOfInterestProperties;
    coordinates: [number, number];
  }
  type FaqQA = {
    question: string;
    answer: string;
  };

  type PartnerConfig = {
    defaultPois: PointOfInterestProperties[];
    faqs: FaqQA[];
  };

  type DeviceConfig = {
    inService: boolean;
    regionId: string;
  };
}

export const getPoints = (
  geoms: Drt.TrailGeometry[],
  includePois: boolean = true
) => {
  const points = geoms.flatMap((g) => {
    switch (g.type) {
      case "LineString": {
        return g.coordinates;
      }
      case "MultiLineString": {
        return g.coordinates.flat();
      }
      case "Point": {
        return includePois ? [g.coordinates] : [];
      }
      default: {
        return [];
      }
    }
  });
  return points;
};

const getBoundingBox = (geoms: Drt.TrailGeometry[], includePois: boolean) => {
  const points = getPoints(geoms, includePois);
  let minLat = 360,
    minLng = 360,
    maxLat = -360,
    maxLng = -360;

  points.forEach(([lng, lat]) => {
    if (lat < minLat) minLat = lat;
    if (lng < minLng) minLng = lng;
    if (lat > maxLat) maxLat = lat;
    if (lng > maxLng) maxLng = lng;
  });
  const bounds = {
    topLeft: { lat: minLat, lng: minLng },
    bottomRight: { lat: maxLat, lng: maxLng },
  };
  return bounds;
};

export const getRegionBoundingBox = (
  region: Drt.Region,
  includePois: boolean = true
) => {
  const geoms = region.features.flatMap((f) => f.geometry.geometries);
  return getBoundingBox(geoms, includePois);
};

export const getTrailBoundingBox = (
  trail: Drt.Trail,
  includePois: boolean = true
) => getBoundingBox(trail.geometry.geometries, includePois);
