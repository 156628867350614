import React, { useCallback } from "react";
import { Box, Paper } from "@mui/material";
import "@aws-amplify/ui-react/styles.css";
import { useGetDashboardQuery } from "../../util/client/TcApi";
import AdminDashboard from "./AdminDashboard";
import PartnerDashboard from "./PartnerDashboard";
import { useParams } from "react-router-dom";

const DashboardPage = () => {
  const { partnerId } = useParams();
  const { data, refetch } = useGetDashboardQuery({ partnerId: partnerId });
  const onRefresh = useCallback(() => refetch(), [refetch]);
  if (data && "partners" in data) {
  }
  return (
    <Paper sx={{ minHeight: "100vh", width: "100%", pt: 8, overflowY: "auto" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pt: 2,
        }}
      >
        {data && "partners" in data && (
          <AdminDashboard {...data} onRefresh={onRefresh} />
        )}
        {data && "partner" in data && (
          <PartnerDashboard {...data} onRefresh={onRefresh} />
        )}
      </Box>
    </Paper>
  );
};

export default DashboardPage;
