import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PartnerApi } from "../../lib";

export interface AuthState {
  accessToken?: string;
  role?: PartnerApi.GetPlanResponse;
}

const initialState: AuthState = {
  accessToken: undefined,
  role: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | undefined>) => {
      state.accessToken = action.payload;
    },
    setRole: (
      state,
      action: PayloadAction<PartnerApi.GetPlanResponse | undefined>
    ) => {
      state.role = action.payload;
    },
  },
});

export const { setToken } = authSlice.actions;

export default authSlice.reducer;
