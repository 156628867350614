import { Authenticator } from "@aws-amplify/ui-react";
import { Box, Paper } from "@mui/material";
import React from "react";
import { Navigate } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";

const LoginPage = () => {
  return (
    <Paper sx={{ minHeight: "100vh", width: "100%", pt: 8 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: 2,
        }}
      >
        <Authenticator
          loginMechanisms={["email"]}
          hideSignUp={false}
          variation="modal"
        >
          <Navigate to={"/dashboard"} replace />;
        </Authenticator>
      </Box>
    </Paper>
  );
};

export default LoginPage;
