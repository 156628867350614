//@ts-ignore
import tj from "@mapbox/togeojson";
import { v4 as uuid } from "uuid";
import { Drt } from "../lib/Drt";
import { trailTemplate } from "./file-templates";

export const getTrailFromUpload = (file: File): Promise<Drt.Trail> => {
  const fileExtension = file.name
    .substring(file.name.lastIndexOf(".") + 1)
    .toLowerCase();

  return new Promise<Drt.Trail>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = (e) => {
      const result = reader.result as string;
      switch (fileExtension) {
        case "kml":
          resolve(handleKmlString(result));
          break;
        case "gpx":
          resolve(handleGpxString(result));
          break;
        case "geojson":
          resolve(handleGeojsonString(result));
          break;
      }
    };
    reader.readAsText(file);
  });
};

const handleGeojsonString = (input: string): Drt.Trail => {
  const json = JSON.parse(input);
  return kmlGeoJsonToTrail(json);
};

const handleKmlString = (input: string): Drt.Trail => {
  const doc = new DOMParser();
  const document = doc.parseFromString(input, "text/xml");
  const converted = tj.kml(document);
  return kmlGeoJsonToTrail(converted);
};

const handleGpxString = (input: string): Drt.Trail => {
  const doc = new DOMParser();
  const document = doc.parseFromString(input, "text/xml");
  const converted = tj.gpx(document);
  return kmlGeoJsonToTrail(converted);
};

export const readRegionFile = async (file: File): Promise<Drt.Region> => {
  return new Promise<any>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = (e) => {
      const result = JSON.parse(reader.result as string);
      resolve(result);
    };
    reader.readAsText(file);
  });
};

const kmlGeoJsonToTrail = (geoJson: GeoJSON.FeatureCollection): Drt.Trail => {
  const trail = trailTemplate();
  trail.properties.name = geoJson.features[0]?.properties?.name;
  const geometries = geoJson.features.flatMap((f) => {
    if (f.geometry.type === "GeometryCollection") {
      return f.geometry.geometries.flatMap(geometryToTrailGeometry);
    } else {
      return geometryToTrailGeometry(f.geometry, f.properties);
    }
  });
  trail.geometry.geometries = geometries as Drt.TrailGeometry[];
  return trail;
};

const geometryToTrailGeometry = (
  geometry: GeoJSON.Geometry,
  properties?: any
): Drt.TrailGeometry[] => {
  if (geometry.type === "MultiLineString") {
    const { coordinates, ...restGeometry } = geometry;
    const newCoords = coordinates.map((coords) =>
      coords.map(([lng, lat]) => [lng, lat])
    ) as [number, number][][];
    return [
      {
        ...restGeometry,
        coordinates: newCoords,
        properties: {
          color: "",
          direction: "bidirectional",
          id: uuid(),
        },
      },
    ];
  } else if (geometry.type === "LineString") {
    const { coordinates, ...restGeometry } = geometry;
    const newCoords = coordinates.map(([lng, lat]) => [lng, lat]) as [
      number,
      number
    ][];
    return [
      {
        ...restGeometry,
        coordinates: newCoords,
        properties: {
          color: "",
          direction: "bidirectional",
          id: uuid(),
        },
      },
    ];
  } else if (geometry.type === "Point") {
    const { coordinates, ...restGeometry } = geometry;
    const [lng, lat] = coordinates;
    return [
      {
        ...restGeometry,
        coordinates: [lng, lat],
        properties: {
          icon: "",
          iconColor: "",
          pinColor: "",
          pinBorderColor: "",
          text: properties?.name,
          id: uuid(),
        },
      },
    ];
  } else {
    console.warn("imported kml with an unknown type", geometry.type);
    return [];
  }
};
