import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { Drt } from "../../lib/Drt";

type PathEditorProps = {
  path: Drt.TrailPath;
  trailId?: string;
  extractGeometryToTrail: (
    sourceTrail: string,
    sourceGeometry: string,
    targetTrailId?: string
  ) => void;
  trailList: { id: string; name: string }[];
};

const PathEditor = ({
  path,
  extractGeometryToTrail,
  trailId,
  trailList,
}: PathEditorProps) => {
  const [selectedTrailId, setSelectedTrailId] = useState<string>("new");
  const onExtractClick = useCallback(
    () =>
      trailId &&
      extractGeometryToTrail(
        trailId,
        path.properties.id,
        selectedTrailId === "new" ? undefined : selectedTrailId
      ),
    [path, trailId, selectedTrailId]
  );
  const options = useMemo(
    () => [{ id: "new", name: "New Trail" }, ...trailList],
    [trailList]
  );
  const onTrailChange = useCallback(
    (event: SelectChangeEvent) => {
      setSelectedTrailId(event.target.value);
    },
    [setSelectedTrailId]
  );
  return (
    <Box>
      <Select
        label="Destination Trail"
        size="small"
        value={selectedTrailId}
        onChange={onTrailChange}
        fullWidth
      >
        {options.map((opt) => (
          <MenuItem value={opt.id} key={opt.id}>
            {opt.name}
          </MenuItem>
        ))}
      </Select>
      <Button onClick={onExtractClick}>Extract to Trail</Button>
    </Box>
  );
};

export default PathEditor;
