import { useAuthenticator } from "@aws-amplify/ui-react";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { RootState } from "../../store/store";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const token = useSelector((state: RootState) => state.auth.accessToken);
  const { route } = useAuthenticator((context) => [context.route]);
  let location = useLocation();
  if (route === "setup" || route === "signIn") {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (route === "authenticated" && token) {
    return children;
  }
  return <></>;
};

export default RequireAuth;
