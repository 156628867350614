import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useCallback, useState } from "react";
import TcForm from "../../components/tc-form/TcForm";
import DashboardCard from "./DashboardCard";
import { createUserForm } from "../../forms/createUserForm";

type CreateUserProps = {};

const CreateUser = ({}: CreateUserProps) => {
  const [formData, setFormData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const onSubmit = useCallback(() => {
    console.log("creating user", formData);
  }, [formData]);
  const onRequestClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const onOpenDialog = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  return (
    <>
      <DashboardCard>
        <Box
          onClick={onOpenDialog}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            width: "100%",
            height: "100%",
          }}
        >
          <AddIcon fontSize="large" />
          <Typography>Add User</Typography>
        </Box>
      </DashboardCard>
      <Dialog open={isOpen} onClose={onRequestClose}>
        <DialogTitle>Create User</DialogTitle>
        <DialogContent>
          <Box sx={{ minWidth: 500 }}>
            <TcForm schema={createUserForm} onFormChange={setFormData} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onRequestClose}>Cancel</Button>
          <Button onClick={onSubmit}>Create</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateUser;
