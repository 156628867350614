import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import DashboardCard from "./DashboardCard";
import EditIcon from "@mui/icons-material/Edit";
import MapIcon from "@mui/icons-material/Map";
import { ApiCommon, PERMISSIONS } from "../../lib";
import usePermissions from "../../util/auth/usePermissions";
import PermGuard from "../../util/auth/PermGuard";

type DeviceCardProps = {
  device: ApiCommon.Device;
  partnerId: string;
};
const DeviceCard = ({ device, partnerId }: DeviceCardProps) => {
  return (
    <DashboardCard>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Typography>{device.name}</Typography>
        <Box sx={{ display: "flex" }}>
          <PermGuard perms={PERMISSIONS.DEVICE_UPDATE}>
            <Link to={`/partner/${partnerId}/device/${device.id}`}>
              <IconButton aria-label="edit" color="primary">
                <EditIcon />
              </IconButton>
            </Link>
          </PermGuard>
          <Link
            to={`/partner/${partnerId}/map/${device.regionId}/device/${device.id}`}
          >
            <IconButton aria-label="view map" color="primary">
              <MapIcon />
            </IconButton>
          </Link>
        </Box>
      </Box>
    </DashboardCard>
  );
};

export default DeviceCard;
