import { v4 as uuid } from "uuid";
import { Drt } from "../lib/Drt";

export const trailTemplate = (): Drt.Trail => ({
  type: "Feature",
  properties: {
    name: "Trail Name",
    description: "Description",
    trailType: "p2p",
    completionTime: "2 hours",
    trailLength: "6.3 miles",
    trailDifficulty: "Intermediate",
    id: uuid(),
  },
  geometry: {
    type: "GeometryCollection",
    geometries: [],
  },
});

export const poiTrailTemplate = (): Drt.Trail => {
  const trail = trailTemplate();
  trail.properties = {
    ...trail.properties,
    name: "POI",
    description:
      'The POI "trail" is a collection of features that will always be shown on the map and will not be visible to users in the trail list. This should include places like popular landmarks, recommended shops or restaurants in the area, and your place of business.',
    completionTime: "",
    trailLength: "",
    trailDifficulty: "",
  };
  return trail;
};
